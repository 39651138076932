@import 'dashboards/shared/styles/variables';

.UIBanner {
  border-radius: 20px;
  padding: 20px;
  color: $white;

  & a,
  a:hover {
    color: $white;
    font-weight: $font-semibold;
  }
}

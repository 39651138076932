@import '@alkem/react-styles/src/vars';

.FieldSuggestionActionBar__row {
  display: flex;
  align-items: center;
  height: 100%;
  margin-left: 6px;
}

.FieldSuggestionActionBar_checkbox {
  display: flex;
  margin: 0 1rem;
  align-items: center;
  label {
    margin: 0;
  }
}

.FieldSuggestionActionBar__button {
  margin: 0 5px;
  .btn {
    background: transparent;
  }
}

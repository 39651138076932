.OrganizationPageRecipients_title {
  flex: 1;
  text-align: right;
  padding-right: 1rem;
}

.OrganizationPageRecipients_container {
  flex: 3;
}

.OrganizationPageRecipients_relations {
  list-style: none;
  margin: 0 0 2rem 1rem;
  padding: 0;
}

.OrganizationPageRecipients_relation {
  margin: 0 0 0.75rem 0;
}

.OrganizationPageRecipients_add {
  cursor: pointer;
  margin-left: 0.5rem;
}

.OrganizationPageRecipients_relationRemove {
  cursor: pointer;
  margin-right: 0.5rem;
  padding: 0;
}

.OrganizationPageRecipients_form {
  display: flex;
  align-items: center;

  .OrganizationPageRecipients_formOrganization {
    flex: 3;
  }
  .OrganizationPageRecipients_formRelationType {
    flex: 3;
    margin-left: 0.5rem;
    .SimpleSelect__content {
      height: 38px;
    }
  }
  .OrganizationPageRecipients_add {
    flex: 1;
  }
}

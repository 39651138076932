.CommandPalette__Result {
  display: flex;
  flex-direction: column;
  border: solid thin;
  margin-top: 10px;
  border-radius: 5px;
}

.CommandPalette__ResultQuery {
  text-align: center;
  border-bottom: solid thin;
  padding: 10px;
  width: 100%;
}

.CommandPalette__ResultComponent {
  padding: 15px;
}

.ReplayDetailsModal {
  width: 100%;
  height: 100%;

  .ReplayDetailsModal__Body {
    height: 100%;
    display: flex;
    flex-flow: column;
  }
}

.ReplayDetailsModal_Button {
  text-align: right;
}

.ReplayDetailsModal_ParameterName {
  width: 100%;
}

.ReplayDetailsModal_ParameterValue {
  width: 100%;
}

.ReplayDetailsModal_ParameterDelete {
  cursor: pointer;
}

.ReplayDetailsModal_Parameter {
  margin-bottom: 5px;
}

@import '@alkem/react-styles/src/vars';

.ImportFileList__spinner {
  text-align: center;
  margin-top: 250px;
}

.ImportFileList__sep {
  padding-left: 5px;
}

.ListController__Actions {
  display: flex;
}

.ListController__Actions__col {
  display: flex;
  align-items: center;
}

@use 'sass:math';

@import '@alkem/react-styles/src/vars';

.ValidationRulesList {
  align-items: center;

  .btn.round {
    border-radius: 50%;
  }

  #SimpleSelect-choiceTree-itemsPerPage {
    z-index: 100;
  }
}

.ValidationRuleGeneralCheckboxContainer {
  padding: 5px;
  padding-left: 11px;
}

.ValidationRulesList__sheet {
  position: relative;
}

.ValidationRulesList__headerLabel {
  display: flex;
  width: 100%;
  height: 48px;
  justify-content: center;
  align-items: center;
  font-weight: 500;
  position: sticky;
  top: 40px;
  background-color: #ececec;
  z-index: 5;
}

.ValidationRulesList__headerLabel:before,
.ValidationRulesList__headerLabel:after {
  background-color: #000;
  content: '';
  display: inline-block;
  height: 1px;
  position: relative;
  vertical-align: middle;
  width: 33%;
}

.ValidationRulesList__headerLabel:before {
  right: 56px;
  margin-left: -35%;
}

.ValidationRulesList__headerLabel:after {
  left: 56px;
  margin-right: -35%;
}

.ValidationRulesList__spinner {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 300px;
}

.ValidationRule__templateType {
  background-color: $gray-slightly-lighter;
  border-radius: 3px;
  padding-left: $padding-xs-horizontal;
  padding-right: $padding-xs-horizontal;
  text-transform: uppercase;
  margin-right: $half-margin-base;
}

.ValidationRule {
  border-radius: 5px;
  border-color: white;
  box-shadow: 0px 1px 1px 1px rgba(0, 0, 0, 0.1);
  background-color: $gray-lightest;
  padding: $padding-base-vertical;
  margin-top: math.div($half-margin-base, 2);
  gap: 0.5em;

  code {
    max-width: 40%;
    word-wrap: break-word;
    white-space: pre-wrap;
  }

  &:hover {
    border-color: lighten($brand-secondary-hover, 10);
    background-color: lighten($brand-secondary-hover, 10);
  }

  .ValidationRule__summary {
    padding: 5px;
    cursor: auto;
    background-color: white;
  }

  .InputWithLabel {
    flex-grow: 1;
    .InputWithLabel__label {
      flex-basis: 20%;
      text-align: right;
    }
    .InputWithLabel__input {
      flex-basis: 80%;
      max-width: 80%;
    }
  }

  .ValidationRuleTags {
    cursor: auto;
  }

  .expandable {
    font-size: 80%;
    transition: all 0.2s ease-in-out;
    flex-grow: 1;
  }

  .formattedJSON {
    padding: 0.2rem 0.7rem;
    background-color: $readonly-bg;
    color: $brand-secondary-menu;
    .string {
      color: $brand-inprogress;
    }
    .number {
      color: $brand-success;
    }
    .boolean {
      color: $brand-primary;
    }
    .null {
      color: $brand-warning;
    }
  }

  .ValidationRuleCheckboxContainer {
    padding: 5px;
    align-self: center;

    &--highlighted {
      background-color: #166a832d;
      border-radius: 50%;
    }

    .ReactUiCheckbox {
      margin-bottom: 0;
    }
  }

  .ValidationRuleContainer {
    flex: 1;
    flex-direction: column;
    cursor: pointer;
    gap: 0.5em;

    .ValidationRuleHeader {
      align-items: center;
    }

    .ValidationRuleInfos {
      align-items: center;
      flex-wrap: wrap;
    }
  }

  .ValidationRuleInfos__ShowSummaryButton {
    margin: 0 0.25rem;
    padding: 0.25rem 0.5rem;
  }
}

.ValidationRule__showApplierButton {
  text-align: center;
}

.ValidationRule__applyButton {
  text-align: right;
  margin-top: $half-margin-base;
  margin-bottom: $half-margin-base;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.ValidationRule__applicationResult {
  font-size: 1.1rem;
  color: $gray-medium;

  &---1 {
    font-style: italic;
  }

  &--0 {
    color: $brand-success;
    font-weight: bold;
  }

  &--1 {
    color: $brand-danger;
    font-weight: bold;
  }

  &--2 {
    color: $brand-inprogress;
    font-weight: bold;
  }

  &--3 {
    color: $alk-purple;
    font-weight: bold;
  }

  &--4 {
    color: $alk-red;
    font-weight: bold;
  }
}

.ValidationRule__applierTipster {
  padding: $padding-large-horizontal;
  border: 1px solid $brand-primary;
  border-radius: $border-radius-base;
  background-color: lighten($brand-primary, 30%);
}

.ValidationRule__documentation {
  padding: 0.5rem 0;
  white-space: pre;
  .empty-content {
    display: none;
  }
  &.empty {
    .empty-content {
      display: inline-block;
      font-style: italic;
      color: $gray-medium;
    }
  }
}

@import '@alkem/react-styles/src/vars';

.OrganizationSSO {
  &__modal {
    &__body {
      max-width: initial !important;

      table {
        width: 100%;
      }
    }
  }
}

@import '@alkem/react-styles/src/vars';

.container {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 6px;
  border: 1px solid $gray-light;
  margin-bottom: 10px;
  border-radius: $border-radius-large;
}

.headerOpened {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  border-bottom: 1px solid $gray-light;
  margin-bottom: 10px;
  padding-bottom: 6px;
}

.headerClosed {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.buttonsContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
}

.infos {
  display: flex;
  flex-direction: column;
}

.nameContainer {
  flex: 1;
}

.arrowButton {
  cursor: pointer;
}

.codeEditorsContainer {
  display: flex;
}

.organizationAutocomplete {
  margin-top: 0.25em;
  margin-bottom: 0.25em;
}

.modalTestContainer {
  display: flex;
  flex-direction: column;
  :global(.InputWithLabel) {
    margin: 0.25em;
    :global(.InputWithLabel__label) {
      label {
        text-align: right;
      }
    }
  }
  button {
    margin-right: 0px;
    margin-top: 0.25em;
    align-self: flex-end;
  }
}

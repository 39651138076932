@import '@alkem/react-styles/src/vars';

.childContainer {
  margin-left: $margin-base-horizontal;
}
.modalButtonsContainer {
  display: flex;
  justify-content: center;
  flex-flow: column;
}
.childNamesContainer {
  display: flex;
  cursor: pointer;
}
.elementSelectorDisabled {
  pointer-events: none;
  opacity: 0.4;
}

.newXpath {
  font-weight: bold;
  color: $alk-blue;
}

.button {
  padding: 1px 2px 1px 2px;
}

.modalText {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
}

.inactiveElement {
  color: $gray-light;
}

@import '@alkem/react-styles/src/vars';
@import 'react-daterange-picker/src/css/react-calendar';

$date-height: 30px;

.DateFilter {
  display: flex;
  justify-content: center;
}

.DateFilter__wrapped {
  display: flex;
  align-items: center;
  border: 1px solid $input-border-color;
  width: 100%;
  margin: $padding-base-vertical $padding-base-horizontal;
}

.DateFiler__input {
  width: 100%;
  border: none;
  padding-left: $padding-base-horizontal;
  background: #fff;
}

.DateFiler__calendarIcon {
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  font-size: 20px;
  width: $date-height;
  height: $date-height;
  background: $brand-primary;
  border: 0;
  color: #fff;
  cursor: pointer;
}

.DateFiler__calendar {
  display: block;
  width: 315px;
  position: absolute;
  left: 0;
  right: 0;
  padding: 0;
  margin: 0;
  z-index: 1;
  border: 1px solid $brand-primary;
  background: white;
}

.DateRangePicker__WeekdayHeading {
  font-weight: 300;
  cursor: default;
}

.DateRangePicker__CalendarSelection {
  background-color: $brand-primary;
  border-color: $brand-primary;
}

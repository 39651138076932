.ShowReferential__fieldLabel {
  font-weight: normal;
  flex: 1;
}

.ShowReferential__fieldResult {
  flex: 4;
}

.ShowReferential__container {
  display: flex;
  flex-direction: column;
}

.ShowReferential__container_row {
  display: flex;
  flex: 1;
}

.ShowReferential__previewEntityRow {
  display: flex;
  flex: 1;
}

.ShowReferential__previewNavigator {
  display: flex;
  flex-direction: row;
  margin-bottom: 15px;

  & > .ShowReferential__previewNavigatorSearch {
    flex: 3;
    border-radius: 2px;
    border: solid 1px black;
  }

  & > .ShowReferential__previewNavigatorButton {
    flex: 0.5;
    border-inline: None;
    border: 1px black;
    border-radius: 2px;
    margin-right: 5px;
  }

  & > .ShowReferential__previewNavigatorPageIndicator {
    flex: 1;
    margin-left: 15px;
    padding: 5px;
    text-align: right;
  }
}

@import 'dashboards/shared/styles/variables';

.UIFileInput {
  position: relative;
  display: flex;
  align-items: center;

  &__Input {
    display: none;
  }

  &__Button {
    height: 48px;
    background-color: $PRIMARY;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    padding: 0 20px;
    color: $white;
    font-size: $font-medium;
    border: unset;
  }

  &__Text {
    height: 48px;
    flex: 1;
    display: flex;
    align-items: center;
    padding: 0 20px;
    border: 1px solid $gray-light;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    font-size: $font-medium;
  }

  &__Clear {
    position: absolute;
    right: 15px;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
    font-size: $font-large;
    color: $gray;
  }
}

@import '@alkem/react-styles/src/vars';

.GDSNImportMappingsFieldSelectorItem {
  margin-left: $margin-base-horizontal;
}

.GDSNImportMappingsFieldSelectorItem__line {
  display: flex;
  > i {
    font-size: large;
    cursor: pointer;
  }
}

.GDSNImportMappingsFieldSelectorItem__label {
  cursor: pointer;
  overflow: hidden;
}

.GDSNImportMappingsFieldSelectorItem__chevron--hidden {
  color: $brand-white;
}

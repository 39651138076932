@import '@alkem/react-styles/src/vars';

$row-height-small: 32px;
$row-height: 64px;

.ExportListRowWrapper {
  margin: $padding-small-vertical 0;

  .ExportListRow {
    cursor: auto;
    width: 100%;

    background-color: white;
    border: 1px solid $gray-border;
    min-height: $row-height;
    align-items: center;

    &:hover {
      background-color: $alk-background-hover;
    }

    &.active {
      background-color: $brand-primary-active;
      color: white;
    }
  }
}

.ExportButton_no_padding {
  padding: 0px;
}

.ExportListRow_smally {
  font-size: $font-size-small;
}

.ExportListRow_boldy {
  font-weight: bolder;
}

.ExportListRow_actions {
  float: right;
}

.ExportListRow_StatusBar {
  height: $row-height;
  padding-left: 0;
}

.ExportListRow_RemoteFiles {
  display: inline-flex;
  font-size: 1.6rem;
}

.ExportListIconMini {
  cursor: pointer;
}

.ExportListIcon {
  font-size: 24px;
  cursor: pointer;

  .hidden {
    visibility: hidden;
  }
}

.ExportDetailsRowWrapper {
  cursor: auto;
  margin-left: $padding-large-horizontal;
  margin-right: $padding-large-horizontal * 2;
  border: 0px;
  width: 100%;

  .ExportDetailsRow__Lines {
    border-bottom: 1px solid $gray-border;
    margin-right: -7.5px;
    align-items: center;
    background-color: white;
  }

  .ExportDetailsRow__actions {
    text-align: right;

    .hidden {
      visibility: hidden;
    }
  }

  .ExportDetailsRow {
    cursor: auto;
    margin-right: 0px;

    font-size: 14px;
    background-color: white;
    height: $row-height-small;
    align-items: center;
    border-right: 1px solid $gray-border;
    border-left: 1px solid $gray-border;

    &.ExportDetailsRow__Header {
      margin-right: -7.5px;
      border-bottom: 1px solid $gray-border;
      border-right: 1px solid $gray-border;
      border-left: 1px solid $gray-border;
    }

    &.ExportDetailsRow__Search {
      height: $row-height-small * 1.5;
      margin-right: -7.5px;
    }

    &:hover {
      background-color: $alk-background-hover;
    }

    .ExportDetailsRow_Navigation {
      display: flex;
      align-items: center;
      justify-content: flex-end;

      .ExportDetailsRow_NavigationSeparator {
        width: 15px;
      }
    }
  }
}

.ExportDetailsRow_Navigation {
  display: flex;
  align-items: center;
  justify-content: flex-end;

  .ExportDetailsRow_NavigationSeparator {
    width: 15px;
  }
}

.ExportListStepRowWrapper {
  width: 100%;
  margin-left: 10px;
  margin-right: 10px;
  font-size: $font-size-small;

  .ExportListStepRow {
    width: 100%;

    background-color: white;
    border-bottom: 1px dashed $gray-border;
    height: $row-height-small;
    align-items: center;

    &.ExportListStepRow__Header {
      font-weight: bolder;
    }
  }
}

.ExportFileListRow__tag {
  width: 100%;
  font-size: $font-size-small;
  color: #fff;
  background-color: $brand-secondary;
  border-radius: $border-radius-very-large;
  text-align: center;
  padding: 2px 6px;
  border: 1px solid $brand-secondary;
  margin: 2px;
}

.ExportListDetailsIcon {
  text-align: right;
  min-width: 14%;
}

.ExportListDetailsIconDoc {
  cursor: pointer;

  .hidden {
    visibility: hidden;
  }
}

.ExportListDetailsMissingFile {
  font-size: $font-size-small;
}

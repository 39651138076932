@import '@alkem/react-styles/src/vars';

.listContainer {
  display: flex;
}

.inactiveElement {
  color: $gray-light;
}

.button {
  padding: 1px 2px 1px 2px;
}

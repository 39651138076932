@import '@alkem/react-styles/src/vars';

.ConditionsDisplay {
  gap: 1.5rem;

  .pre {
    font-size: 90%;
    font-family:
      'Roboto Mono', Menlo, Monaco, Consolas, 'Courier New', monospace;
    display: block;
    word-wrap: break-word;
    white-space: pre-wrap;
    word-break: break-word;
  }

  .ConditionDisplayItem__errorDisplay {
    font-size: 80%;
    font-family:
      'Roboto Mono', Menlo, Monaco, Consolas, 'Courier New', monospace;
  }

  .ConditionDisplayItem__usedToDisplayErrors {
    color: $alk-green;
    font-weight: bold;
  }

  .ConditionDisplayItem__notUsedToDisplayErrors {
    color: $alk-red;
    font-weight: bold;
  }

  .ConditionDisplayItem__copyButton,
  .ConditionDisplayText__copyButton {
    padding: 0;
  }

  .ConditionDisplayItem__copyTooltip,
  .ConditionDisplayText__copyTooltip {
    margin: auto 1rem;
  }

  .ConditionDisplayItems__constants {
    margin-bottom: 2rem;
  }

  .ConditionDisplayItems {
    gap: 1rem;

    .ConditionDisplayItem {
      code {
        max-width: 40%;
        word-wrap: break-word;
        white-space: pre-wrap;
      }

      .string {
        color: $brand-inprogress;
      }
      .number {
        color: $brand-success;
      }
      .boolean {
        color: $brand-primary;
      }
      .null {
        color: $brand-warning;
      }
    }
  }
}

.ConditionsEditor {
  & > .InputWithLabel {
    align-items: center;
    margin-bottom: 0.5rem;
  }

  .ConditionEditorItemsList {
    gap: 2rem;

    .InputTextarea__input.form-control {
      border: 1px solid $input-border-color;
      min-height: 40px;
      padding: 8px;

      &.InputTextarea__input--invalid {
        border-color: $alk-red;
      }
      &:focus {
        border-color: $brand-primary;
      }
    }
    .ConditionEditorItems {
      margin-bottom: 1rem;
      border-left: 1px solid $input-border-color;
    }
    .ConditionEditorItem {
      margin-bottom: 0.25rem;
    }
    .ConditionEditorItem__key {
      flex-grow: unset;
    }
    .ConditionEditorItem__value {
      flex-grow: 1;
      margin: 0 0.2rem;
    }
    .ConditionEditorItem__errorDisplay {
      margin: 0.4rem 0 1.8rem 0.6rem;
    }
  }

  .SwitchButton {
    flex-direction: row-reverse;
  }

  .SwitchButton__Label {
    margin-right: 0.8rem;
  }

  .InputTextarea,
  .InputField {
    flex-grow: 1;
    margin: 0 0.2rem;
  }

  .btn {
    &.small-padding {
      padding: 0.5rem;
    }
    &.bg-white {
      background-color: white;
      &:hover:not(.disabled) {
        color: $brand-primary;
      }
    }
    &.btn-link {
      &:hover.disabled {
        color: $brand-primary;
      }
    }
  }
}

@import '@alkem/react-ui-table/src/index';

.OrganizationLines__ruleset {
  background-color: #f5f5f5;
}

.ValidationRuleOrganizations {
  tbody {
    max-height: 500px;
    overflow-y: auto;
    overflow-x: hidden;
  }
  .GenericTable__header {
    th {
      position: sticky;
      top: -1rem;
      background-color: white;
    }
  }
  .GenericTable {
    .center {
      text-align: center;
      vertical-align: middle;
    }
  }
}

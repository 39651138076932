@import '@alkem/react-styles/src/vars';

.textField {
  margin-bottom: 0.25em;
}

.container {
  :global(.InputWithLabel) {
    .errorInput {
      :global(.TurboSelect__control) {
        border-color: $alk-red;
      }
    }
  }
}

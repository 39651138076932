.TransactionExplorerList__Header {
  text-align: right;
}

.TransactionExplorerList__Layer {
  display: flex;
  justify-content: center;
}

.TransactionExplorerList__Arrow {
  display: flex;
  justify-content: center;

  .mdi {
    background: white;
    border-radius: 50%;
    font-size: 1.2rem;
    padding: 1rem;
    margin: 0.5rem 0;
  }
}

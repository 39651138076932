@import '@alkem/react-styles/src/colors';

.OrganizationFilterLite__checkbox {
  display: flex;
  align-items: center;
  border: 1px solid $input-border-color;
  margin-bottom: 0.25rem;

  & > input {
    flex-grow: 1;
    width: 100%;
    border: none;
    padding: 0.25rem 0.5rem;
    outline: none;
  }

  & > i {
    padding: 0 0.25rem;
    cursor: pointer;
  }
}

@import 'dashboards/shared/styles/variables';

.UIImportDetailsModal {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  padding: 20px;
  border-radius: 20px;
  max-height: 90vh;
  min-width: 90vw;
  max-width: 90vw;
  overflow-y: auto;
  &::-webkit-scrollbar {
    display: none;
  }

  &__Container {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }

  &__Grid {
    display: grid;
    grid-template-columns: 1fr 2fr 1fr;
    gap: 20px;
  }

  &__Table {
    &__Steps {
      width: 100%;
      display: flex;
      gap: 4px;
      align-items: center;

      overflow-x: scroll;
      &::-webkit-scrollbar {
        display: none;
      }
    }

    &__Actions {
      width: 100%;
      display: flex;
      align-items: center;

      & > i {
        background-color: $white;
        width: 28px;
        height: 28px;
        border-radius: 50%;
        border: 1px solid $PRIMARY;
        cursor: pointer;
        color: $PRIMARY;
        font-size: 16px;
        display: grid;
        align-items: center;
        justify-content: center;

        &:hover {
          background-color: $PRIMARY;
          color: $white;
        }
      }
    }
  }
}

@import 'dashboards/shared/styles/variables';

.UIButton {
  height: 48px;
  background-color: $PRIMARY;
  border-radius: 10px;
  padding: 0 24px;
  color: $white;
  font-size: $font-medium;
  border: unset;

  &:disabled {
    background: $gray-light;
  }
}

@import 'dashboards/shared/styles/variables';

.UIDateInput {
  width: 100%;

  &__Input {
    width: 100%;
    height: 48px;
    padding: 0 20px;
    border: 1px solid $gray-light;
    border-radius: 10px;
    font-size: $font-medium;
    appearance: none;
    -webkit-appearance: none;

    &:disabled {
      background: $gray-lighter;
    }

    &::-webkit-datetime-edit-fields-wrapper {
      width: 100%;
    }

    &::-webkit-datetime-edit-text {
      padding: 0 10px;
    }

    &::-webkit-calendar-picker-indicator {
      cursor: pointer;
      font-size: 20px;
    }
  }
}

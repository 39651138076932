@import '@alkem/react-styles/src/vars';

.OrganizationApplications {
  button,
  button:focus,
  a,
  a:active,
  a:visited,
  a:hover {
    outline: none;
  }
}

.OrganizationApplications__list {
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  width: 100%;
  padding: 0;
  margin: 0;
  list-style: none;

  & > li {
    flex: 0 0 50%;
  }

  & > li.OrganizationApplications__header {
    flex: 0 0 100%;
    margin-top: 1.5rem;
    margin-bottom: 0.25rem;
    font-size: 1.25em;
    color: $gray-medium;
    border-bottom: 1px solid $gray-medium;

    &:first-child {
      margin-top: 0;
    }
  }
}

.OrganizationApplications__showMore.Button.btn {
  width: 100%;
  padding: 0.25rem 0.5rem;
  margin: 1rem 0;

  &:last-child {
    margin-bottom: 0;
  }

  & > .Button__content {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}

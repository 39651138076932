@import '@alkem/react-styles/src/vars';

.FieldSuggestionFilters__singleStatus {
  position: relative;

  & .ReactUiCheckbox__label {
    margin-left: 10px;
  }
}

.FieldSuggestionFilters__statusBar {
  position: absolute;
  height: 15px;
  width: 4px;
  left: 20px;

  &--PENDING {
    background: $brand-secondary;
  }

  &--VALIDATED {
    background: $brand-success;
  }

  &--REFUSED {
    background: rgba($brand-danger, 0.7);
  }

  &--ACCEPTED {
    background: rgba(72, 176, 113, 0.3);
  }

  &--DISMISSED {
    background: $brand-danger;
  }
}

.FieldSuggestionFilters__fmdSelector {
  padding: 5px 20px;
}

.FieldSuggestionFilters__organizations {
  border: 1px solid $gray-light;
  margin: 0 10px 15px 5px;
  padding: 5px 10px;
}

.FieldSuggestionFilters__title {
  font-size: 1.25rem;
  margin: 0 0 5px 0;
  padding: 0;
  font-weight: 300;
}

.FieldSuggestionFilters__fieldset {
  margin: 0;
  padding: 0px $margin-base;
  margin-top: 0.5rem;
  margin-bottom: 0.1rem;
}

.ShowChildren__switch {
  padding: 5px 25px;
}

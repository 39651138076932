@use 'sass:math';

@import '@alkem/react-styles/src/vars';

.RuleSetSelector .Select__inputbox {
  background-color: white;
}

.RuleSetSelector__ruleSet {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: math.div($half-margin-base, 2);
  border: 1px solid $gray-light;
  padding: $padding-base-vertical;
  background-color: $gray-lightest;
}

.RuleSetSelector__delete {
  cursor: pointer;
  font-size: x-large;
  color: $gray-light;

  &:hover {
    color: $gray-dark;
  }
}

@import '@alkem/react-styles/src/vars';

.app-form {
  padding-top: 5px;
}

.app-form .form-control {
  padding-left: 10px;
  height: inherit;
  min-height: 40px;
}
.app-form textarea {
  padding-top: 10px;
}

.app-form .form-control:disabled {
  background-color: inherit;
  border-bottom: none;
}

.app-form input[type='checkbox'] {
  margin-top: 14px;
}

.app-form-label {
  padding-top: 0.8rem;
  font-weight: 400;
  color: #444;
}

.app-featured {
  padding-top: 12px;
}

.form-error input,
.form-error textarea,
.form-error .Autocomplete {
  background: #fee;
}
.form-error-message {
  display: inline-block;
  color: #e64c42;
  margin-top: 3px;
  font-weight: 400;
}

.empty-message {
  color: #aaa;
}

.enableOrganization {
  display: flex;
  padding-top: 40px;
}

.enableOrganization___AddButton {
  margin-left: 40px;
}

.list-group-label {
  flex: 1;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .ApplicableKinds {
    margin: 0;
  }
  margin-right: $margin-base-horizontal;
  margin-left: $padding-xs-horizontal;
}

.list-group-delete {
  font-size: x-large;
  color: $gray-light;
}

.list-group-delete:hover {
  font-size: x-large;
  cursor: pointer;
  color: $gray-dark;
}

.ExportGDSNFileModal_Body {
  height: 100%;
  display: flex;
  flex-flow: column;
}

.ExportGDSNFileModal_Body_Poll {
  text-align: center;
}

.ExportGDSNFileModal_sep {
  margin-top: 15px;
}

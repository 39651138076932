@import '@alkem/react-styles/src/vars';

.ReferentialPictureItem {
  position: relative;
  margin: 0.5rem 0.25rem 0rem 0.25rem;
  border: 1px solid $gray-border;
  background-color: white;
  width: 250px;
  height: 340px;
  text-align: center;
}

.ReferentialPictureItem__picture {
  margin: 1rem;
  height: 210px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.ReferentialPictureItem__name {
  padding: 0 1rem;
  font-weight: 400;
  height: 70px;
  font-size: small;
  overflow: auto;
}

.ReferentialPictureItem__upload {
  position: absolute;
  top: 0.3rem;
  right: 0.5rem;
  font-size: x-large;
  cursor: pointer;

  &:hover {
    color: $brand-primary;
  }
}

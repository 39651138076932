@import '@alkem/react-styles/src/vars';

.fieldList__fieldlabel {
  padding-left: $padding-base-horizontal;
  font-size: 1.1rem;
}

.fieldList__subfieldlabel {
  padding-top: $padding-large-vertical;
  padding-bottom: $padding-base-vertical;
}

.fieldSubList__title {
  font-size: $font-size-large;
  font-weight: $headings-font-weight;
  padding-top: 3 * $padding-large-vertical;
  padding-bottom: $padding-base-vertical;
}

.fieldList__doublelist {
  padding-left: $padding-base-horizontal;
  padding-top: 2 * $padding-large-vertical;
  padding-bottom: $padding-base-vertical;
}

.fieldList__title {
  padding: 2 * $padding-large-vertical $padding-large-horizontal
    $padding-base-vertical $padding-large-horizontal;
}

.FieldList__checkbox {
  height: 2 * $padding-base-horizontal;
  padding-right: $padding-base-horizontal;
}

.FieldList__row {
  display: flex;
  justify-content: space-between;
}

.OrganizationPageSettingEnumRadioController__label .HelpTooltip {
  margin-left: 0.5rem;
}

.OrganizationPageSettingEnumRadioController__radio {
  margin-bottom: 0.5rem;

  .HelpTooltip {
    margin-left: 0.5rem;
  }

  .InputRadio .InputField__input {
    line-height: 20px;
    padding: 0;
  }
}

.OrganizationPageSettingEnumRadioController__radio--withLabel {
  padding-left: 1.5rem;
}

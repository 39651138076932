.list {
  display: flex;
  flex-direction: row;
}

.listFilters {
  width: 25em;
  margin: 1em;
}

.listContent {
  flex-grow: 1;
  margin: 1em;
  display: flex;
  flex-direction: column;
}

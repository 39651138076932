.ShowOrganization__fieldLabel {
  font-weight: normal;
  flex: 1;
}

.ShowOrganization__fieldResult {
  flex: 4;
}

.ShowOrganization__container {
  display: flex;
  flex-direction: column;
}

.ShowOrganization__containerRow {
  display: flex;
  flex: 1;
}

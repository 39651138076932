@import '@alkem/react-styles/src/vars';

.GDSNImportMappingsItemMandatoryProperties__sectionTitle {
  margin-top: 1.25em;
  border-bottom: 1px solid $gray-light;
  font-weight: 500;
}

.GDSNImportMappingsItemMandatoryProperties .InputWithLabel {
  margin-top: 0.25em;
}

.GDSNImportMappingsItemMandatoryProperties .InputWithLabel__input {
  display: flex;
  flex-direction: column;
}

.GDSNImportMappingsItemMandatoryProperties .InputTextarea {
  flex: 1;
}

.GDSNImportMappingsItemMandatoryProperties__buttonContainer {
  margin-top: 0.25em;
  display: flex;
  width: 100%;
  justify-content: flex-end;
}

.GDSNImportMappingsItemMandatoryProperties__sectionTextbox {
  width: 100%;
  display: flex;
  margin-top: 0.25em;
}

.GDSNImportMappingsItemMandatoryProperties__spansContainer {
  display: flex;
  align-items: center;
  flex-direction: row;
  margin-left: 5px;
  justify-content: center;
}
.GDSNImportMappingsItemMandatoryProperties__span--enabled {
  cursor: pointer;
  display: flex;
  color: $brand-primary;
  margin-left: 5px;
}

.GDSNImportMappingsItemMandatoryProperties__span--disabled {
  cursor: none;
  pointer-events: none;
  margin-left: 5px;
}

.GDSNImportMappingsItemMandatoryProperties__textArea {
  flex: 1;
}

.GDSNImportMappingsItemMandatoryProperties__textArea--disabled {
  pointer-events: none;
  opacity: 0.6;
}

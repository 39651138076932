@import '@alkem/react-styles/src/vars';

.EdaViewerContainer {
  display: flex;
  justify-content: center;
}

.EdaViewer {
  border: 1px solid $gray-border;
  background-color: white;
  padding: 1em;
  flex-basis: 80%;
  display: flex;
  flex-direction: column;
  > * + *,
  button {
    margin-top: 1em;
  }
}

.EdaViewerDecodedEvent {
  overflow: visible;
}

.ValidationRule__form {
  justify-content: space-between;
  margin-top: 0;
  display: flex;
  align-items: baseline;
  .InputWithLabel {
    flex-direction: row-reverse;
  }
}

.ValidationRule__checkbox {
  height: 16px;
}

.SortSelect {
  display: flex;
  align-items: center;
}

.SortSelect__label {
  white-space: nowrap;
  padding-right: 0.5rem;
  font-weight: 400;
}

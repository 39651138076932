@import '@alkem/react-styles/src/vars';

#import-hide-empty {
  margin-left: 28px;

  .mdi {
    margin-right: 5px;
  }
}

#import-hide-unknown-gln {
  margin-left: 28px;

  .mdi {
    margin-right: 5px;
  }
}

#import-hide-monitoring-user {
  margin-left: 28px;

  .mdi {
    margin-right: 5px;
  }
}

.ImportFileListFilter__spiderCategory {
  margin-top: 15px;
  margin-bottom: 15px;
  display: flex;
  justify-content: center;

  .btn {
    border-radius: $border-radius-very-large;
    margin-right: 5px;
    margin-left: 5px;
    text-align: center;
    padding: 2px 20px;
  }

  .ImportFileListFilter__spiderCategoryProduct {
    border: 2px dashed $brand-secondary;
    &.active {
      color: #fff;
      border: 2px solid $brand-secondary;
      background-color: $brand-secondary;
    }
  }

  .ImportFileListFilter__spiderCategoryRange {
    border: 2px dashed #ff7449;
    &.active {
      color: #fff;
      border: 2px solid #ff7449;
      background-color: #ff7449;
    }
  }

  .ImportFileListFilter__spiderCategoryRfp {
    border: 2px dashed #c43a7d;
    &.active {
      color: #fff;
      border: 2px solid #c43a7d;
      background-color: #c43a7d;
    }
  }
}

@import '@alkem/react-styles/src/vars';

.GDSNImportMappingsItemChild {
  margin-top: 0.25em;
}

.GDSNImportMappingsItemChild__bordered {
  margin-top: 0.25em;
  border: 1px solid $gray-light;
  border-radius: $border-radius-large;
}

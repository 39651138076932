@import '@alkem/react-styles/src/vars';

.LeftPanelHeader__root {
  display: flex;
  justify-content: space-between;
}

#create-root {
  margin-left: $half-margin-base-horizontal;
}

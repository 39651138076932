@import '@alkem/react-styles/src/vars';

.Notification {
  z-index: 120;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  background: $gray;
  height: 40px;
  transition: height 0.6s;
  color: #fff;
  .Notification__message {
    padding: 20px;
  }
}

.Notification--base {
  position: fixed;
  top: 40px;
  left: 0;
  right: 0;
}

.Notification--modal {
  margin-top: -$margin-base;
  margin-left: -$margin-base;
  margin-right: -$margin-base;
  margin-bottom: 25px;
}

.Notification--hide {
  height: 0;
  border: 0;
}

.Notification--success {
  background: $brand-success;
}

.Notification--error {
  background: $brand-warning;
}

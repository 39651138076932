@import '@alkem/react-styles/src/vars';

.containerDisabled {
  pointer-events: none;
  opacity: 0.6;
}

.sectionTitle {
  margin-top: 1.25em;
  border-bottom: 1px solid $gray-light;
  font-weight: 500;
  margin-bottom: 10px;
  padding-bottom: 6px;
}

@import '@alkem/react-styles/src/colors';

.StatusBar {
  background-color: $gray-medium;
  width: 5px;
  height: 100%;

  &--filter {
    height: 1.5rem;
    margin-right: 0.5rem;
  }

  &--NOT_VALIDATED {
    background-color: $brand-inprogress;
    opacity: 0.3;
  }

  &--VALIDATED {
    background-color: $brand-success;
  }

  &--NOT_ACTIVE {
    background-color: $brand-inprogress;
    opacity: 0.3;
  }

  &--ACTIVE {
    background-color: $brand-success;
  }

  &--IN_PROGRESS {
    background-color: $brand-highlight;
  }

  &--SUCCESS {
    background-color: $brand-success;
  }

  &--CONTAINS_ERRORS {
    background: $alk-orange-darker;
  }

  &--FAILURE {
    background-color: $brand-danger;
  }

  &--ERROR {
    background-color: $brand-danger;
  }

  &--PENDING {
    background-color: $brand-secondary;
  }

  &--REFUSED {
    background: rgba($brand-danger, 0.7);
  }

  &--ACCEPTED {
    background: rgba(72, 176, 113, 0.3);
  }

  &--DISMISSED {
    background: $brand-danger;
  }

  &--TIMEOUT {
    background-color: $alk-purple;
  }

  &--REVIEW {
    background-color: $alk-orange-darker;
  }
}

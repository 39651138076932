.JsonDisplayer__AceEditor {
  width: 100%;
  height: 100%;
  font-size: 1rem;
}

.ace-monokai {
  .mdi.mdi-magnify {
    font-size: 1.3rem;
    margin: 0 3px;
  }

  .form-control.ace-monokai-form {
    color: #777;
    border: 0;
    &:focus {
      color: white;
    }
  }
}

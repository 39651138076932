@import '@alkem/react-styles/src/vars';

.OrganizationPageSettingsSection {
  display: flex;
  margin-bottom: 0.75rem;
  align-items: center;
}

.OrganizationPageSettingsSection__label {
  flex: 1;
  font-weight: 400;
  text-align: right;
  padding-right: 1rem;
}

.OrganizationPageSettingsSection__settings {
  border-left: 3px solid $gray-lighter;
  flex: 3;
  padding-left: 1rem;
}

@import '@alkem/react-styles/src/vars';

$row-height: 110px;

.TransactionRow {
  cursor: auto;

  background-color: white;
  border: 1px solid $gray-border;
  height: $row-height;
  margin: $padding-small-vertical 0;

  align-items: center;

  display: flex;
}
.TransactionRow__id {
  margin-left: 5px;
  width: 20%;
}

.TransactionRow__metadata {
  width: 30%;
  padding: 1em;

  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.TransactionRow__metadata__small {
  font-size: 0.8rem;
}

.TransactionRow__event {
  display: flex;

  strong {
    width: 100%;
  }
}

.TransactionRow__metadataType {
  width: 100%;
  font-size: $font-size-small;
  border-radius: $border-radius-very-large;
  text-align: center;
  padding: $padding-xs-vertical $padding-xs-horizontal;
  border: 1px solid $brand-secondary;
  margin: 1px 0;
}

.TransactionRow__entities {
  height: 100%;
}

.TransactionRow__entities,
.TransactionRow__entities__raw {
  width: 20%;
  margin: 0 0.5rem;
  font-size: 0.9rem;

  padding: 1rem 0;

  .mdi {
    font-weight: bolder;
  }
}

.TransactionRow__entities__raw {
  flex: 1;

  display: flex;
  flex-wrap: wrap;

  max-height: 100%;
  overflow: auto;
}

.TransactionRow__entity__raw {
  border: 1px solid $brand-secondary;
  border-radius: $border-radius-base;
  padding: $padding-small-vertical $padding-small-horizontal;
  margin: $padding-xs-vertical $padding-xs-horizontal;
}

.TransactionRow__displayChoice {
  display: flex;
  flex-direction: column;
  margin: 0 0.5rem;

  .btn {
    color: $gray-dark;

    &:focus {
      outline: none;
    }
  }
}

.TransactionRow__entities.TransactionRow__products {
  flex: 1;
}

.TransactionRow__center {
  text-align: center;
}

.TransactionRow__entityRow {
  height: 100%;
}

.TransactionRow__entityRow__entities {
  max-height: 100%;
  overflow: auto;
}

.TransactionRow .StatusBar--INVISIBLE {
  background-color: black;
}

.TransactionRow__actions {
  text-align: right;
  margin-right: 1rem;

  .btn {
    color: $gray-darker;
    padding: initial;

    &:not(:first-child) {
      margin-left: $padding-xs-horizontal;
    }

    .mdi {
      font-size: 1.2rem;
    }
  }
}

.TransactionRow__moreProducts {
  color: $brand-primary;
}

.TransactionRow__statusHistory {
  flex: 1;
  max-height: 100%;
  overflow: auto;
}

.TransactionRow__statusHistory__row {
  align-items: center;
  margin: 0.2rem 0;
}

.TransactionRow__statusHistory__status {
  width: 16px;
  height: 16px;
  border-radius: 50%;
  margin-left: auto;
  margin-right: auto;

  &--NOT_ACTIVE {
    background-color: $brand-inprogress;
    opacity: 0.3;
  }
  &--IN_PROGRESS {
    background-color: $brand-highlight;
  }

  &--SUCCESS {
    background-color: $brand-success;
  }

  &--CONTAINS_ERRORS {
    background: $alk-orange-darker;
  }

  &--FAILURE {
    background-color: $brand-danger;
  }

  &--PENDING {
    background-color: $brand-secondary;
  }
}

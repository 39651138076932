.CommandPalette__Suggestion {
  display: flex;
  flex-direction: row;
  border: solid thin;
  margin-top: 10px;
  border-radius: 5px;
  padding: 15px;
  width: 100%;
}
.CommandPalette__Suggestion__selected {
  background-color: rgb(227, 238, 248);
}

.CommandPalette__SuggestionCommand {
  flex: 1;
  font-weight: normal;
}

.CommandPalette__SuggestionInfo {
  flex: 3;
  border-left: solid 1px;
  padding-left: 15px;
  margin-left: 15px;
}

.CommandPalette__SuggestionHelp {
  font-style: italic;
}

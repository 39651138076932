.OrganizationPageSettingReadOnlyField__title {
  display: flex;
  align-items: baseline;
}

.OrganizationPageSettingReadOnlyField__title .Button {
  margin-left: 0.5rem;
}

.OrganizationPageSettingReadOnlyField__config {
  margin-left: 1.5rem;
  padding: 1rem 0 0 0;
}

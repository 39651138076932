@import '@alkem/react-styles/src/vars';

.SidePanel {
  position: absolute;
  top: 220px;
  right: 0;
  bottom: 8px;
  overflow: auto;
  width: 600px;

  background: white;
  padding: 0.5rem 1rem;

  box-shadow: 0 0 8px 0px rgba(0, 0, 0, 0.2);

  &--collapsed {
    width: 60px;
    padding: 1rem;
    right: 2rem;
    flex-direction: row;
    writing-mode: vertical-rl;
    text-orientation: sideways;
    cursor: pointer;
  }

  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .SidePanel__summary {
    list-style: none;
    padding-left: 0;
  }
}

.SidePanel__summaryRow {
  padding: $padding-base-vertical $padding-base-horizontal;

  &:not(.SidePanel__summaryRowHeader) .mdi {
    cursor: pointer;
    color: $gray-light;

    &.mdi-eye-off {
      color: $gray;
    }

    &:hover {
      color: $gray-dark;
    }
  }

  display: grid;
  grid-template-columns: auto 50px 50px 50px;
  align-items: center;
}

.SidePanel__summaryRowId {
  cursor: pointer;

  &:hover {
    color: $brand-primary-hover;
  }
}

.ReactModalPortal .NewGroupModal .Modal__body {
  min-height: 200px;
}

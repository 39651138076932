@import '@alkem/react-styles/src/vars';

.Pagination__chevron {
  font-size: x-large !important;
  color: $brand-secondary;
}

.Pagination__btnGroup {
  margin: 0;

  button {
    border-color: $brand-secondary !important;
    padding-left: 3px;
    padding-right: 3px;
  }
}

.Pagination {
  display: flex;
  justify-content: flex-end;
  align-items: center;

  .SimpleSelect {
    margin-right: 1rem;
  }
}

@import '@alkem/react-styles/src/vars';

.OrganizationApplicationItem {
  padding-right: 0.5rem;

  &:hover {
    background-color: $alk-background-hover;

    .OrganizationApplicationItem__icons {
      visibility: visible;
    }
  }

  & > .SwitchButton {
    width: 100%;
    padding: 0.25rem 0;
    display: flex;
    align-items: flex-start;
    line-height: 16px;
  }

  &.OrganizationApplicationItem--changed {
    background-color: lighten($brand-highlight, 30%);
    &:hover {
      background-color: lighten($brand-highlight, 20%);
    }
  }

  .SwitchButton__Label {
    flex: 1;
  }
}

.OrganizationApplicationItem__label {
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;

  .mdi {
    color: $gray;
    &.mdi-settings:hover {
      color: $brand-secondary;
    }
  }
}

.OrganizationApplicationItem__icons {
  visibility: hidden;
  white-space: nowrap;
}

.ReferentialPicturesList {
  width: 100%;
}

.ReferentialPicturesList__spinner {
  margin-top: 1rem;
  text-align: center;
}

.ReferentialPicturesList__container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.OrganizationPageSaveModalSummary__FeatureStatus {
  list-style: none;
  margin-top: 1rem;
}

.OrganizationPageSaveModalSummary__statusIcon {
  margin-right: 0.5rem;

  &--SUCCESS {
    color: green;
  }
  &--ERROR {
    color: red;
  }
}

.OrganizationPageSaveModalSummary__withWarning {
  &--warning {
    margin-left: 0.5rem;
    vertical-align: top;
    display: inline-block;
    width: 300px;
    color: red;
  }
}

.OrganizationPageSaveModalSummary__ErrorMessage {
  border-top: 1px solid #b8b8b8;
  margin-top: 30px;
  padding-top: 30px;
}

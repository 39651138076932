@import '@alkem/react-styles/src/vars';

.GDSNImportMappingsView__body {
  overflow-x: hidden;
}

.GDSNImportMappingsView__content {
  margin: $margin-base $margin-base-horizontal;
  height: calc(99vh - #{$large-header-height} - #{$margin-base * 2});
}

.GDSNImportMappingsView__panel--left {
  width: 400px;
  .TwinPanel__content {
    overflow: hidden;
    height: 100%;
  }
  .GDSNImportMappingsView__fieldSelectorContainer {
    height: 100%;
  }
}

@media only screen and (max-width: 1300px) {
  .GDSNImportMappingsView__panel--left {
    width: 360px;
  }
}

.GDSNImportMappingsView__panel--right {
  flex-grow: 1;
}

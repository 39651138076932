@import '@alkem/react-styles/src/vars';

.Collapsible__collapse-button {
  font-size: 1.2em;
  margin-left: 10px;
  line-height: 2em;
  padding-top: 2px;
}

.Collapsible__header {
  cursor: pointer;

  .right {
    float: right;
    display: flex;
    align-items: center;
  }
}

.Collapsible__content {
  overflow: visible;
}

@import '@alkem/react-styles/src/vars';

.TransactionActionModal {
  width: 66%;
  height: 66%;

  .Spinner__outline {
    stroke: $alk-orange-darker;
  }
}

.TransactionActionModal__Content {
  height: 85%;

  .btn {
    margin: 0 $padding-base-horizontal;
  }

  .btn-outline-success {
    color: $brand-success;
    border-color: $brand-success;

    &:hover {
      color: white;
      background: $brand-success;
      border-color: white;
    }
  }

  .btn-outline-danger {
    color: $brand-danger;
    border-color: $brand-danger;

    &:hover {
      color: white;
      background: $brand-danger;
      border-color: white;
    }
  }

  .btn-outline-warning {
    color: $alk-orange-darker;
    border-color: $alk-orange-darker;

    &:hover {
      color: white;
      background: $alk-orange-darker;
      border-color: white;
    }
  }

  .TransactionActionModal__Hook {
    width: 100%;
    font-size: 1rem;
  }
}

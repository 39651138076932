.GraphActions {
  display: flex;
  align-items: center;
  margin-left: 1rem;
  margin-right: 1rem;
  margin-bottom: 0.5rem;

  > * + * {
    margin-left: 0.5rem;
  }

  .Select {
    width: 300px;
  }
  .Select__inputbox {
    background-color: white;
  }
}

.GraphActions__legend > div {
  margin-right: 0.5rem;
}

.GraphActions__timingGradientLegend {
  width: 120px;
  height: 14px;
  background-image: linear-gradient(to right, #ff0000, #fffe00, #00ff00);
  margin: 0 0.15rem;
}

.GraphActions__colorLegend {
  width: 14px;
  height: 14px;
  border-radius: 50%;
  margin-right: 0.15rem;

  &--fabf2d {
    background-color: #fabf2d;
  }
  &--ef7e1d {
    background-color: #ef7e1d;
  }
  &--4cb963 {
    background-color: #4cb963;
  }
}

.GraphDotError {
  padding: 20px;
}

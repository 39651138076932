@import '@alkem/react-styles/src/vars';

.UserRow {
  &.UserRow--first {
    margin-top: 30px;
  }
}

.UserRow--deactivated {
  background-color: $gray-really-light;
  color: $gray-medium;
}

.ListRowColumn {
  cursor: pointer;
  & > *:not(a):not(button) {
    cursor: initial;
  }
}

.ListRowColumn.UserRow__dual {
  flex: 2;
  min-width: 1px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding-left: 10px;
}

.UserRow__name {
  font-weight: 400;
}

.UserRow__organizationName {
  font-weight: 400;

  &.UserRow__organizationName--pending {
    color: $brand-danger;
    &:hover {
      color: lighten($brand-danger, 10%);
    }
  }
}

.UserRow__username {
  color: $brand-primary;
}

.UserRow__single {
  flex: 1;
  min-width: 1px;

  &.UserRow__edit {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
}

.UserRow__lastLoggedIn {
  font-size: 90%;
}

.UserRow__badge {
  margin-left: -10px;
  border: 1px solid $brand-secondary;
  border-radius: 15px;
  padding-left: 10px;
  padding-right: 10px;
  font-weight: 300;
  font-size: 90%;
  color: $brand-secondary;
}

.UserRow__header {
  display: block;
  position: absolute;
  bottom: 100%;
  margin-bottom: 0.5rem;
  color: $gray-medium;
}

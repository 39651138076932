@import '@alkem/react-styles/src/vars';

.OrganizationBilling__list {
  list-style-type: none;
  padding-top: 5px;
  padding-left: 10px;

  & > li {
    margin-top: 5px;
  }
}

.OrganizationBilling__groupDeactivationRequested {
  .SwitchButton__background--checked {
    background-color: orange;
  }
}

.OrganizationBilling__groupRow {
  display: flex;
}

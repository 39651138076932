@import '@alkem/react-styles/src/vars';

.ReportingHandlingMenu--line {
  padding: 10px 30px;
  font-size: 1rem;
  font-weight: 300;

  h3 {
    font-size: 1.1rem;
    font-weight: 300;
  }
}

.ReportingHandlingMenu--button {
  display: grid;
  text-align: center;
}

.ReportingHandlingMenu--conf__title {
  font-size: 1.2rem;
  display: flex;
  font-weight: 100;
  margin-bottom: 10px;
}

.btn {
  margin: 2px;
}

.without--overlay {
  overflow: hidden;
}

@import '@alkem/react-styles/src/vars';

.sectionTitle {
  margin-top: 1.25em;
  margin-bottom: 10px;
  padding-bottom: 6px;
  border-bottom: 1px solid $gray-light;
  font-weight: 500;
}

.container {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 6px;
  border: 1px solid $gray-light;
  margin-bottom: 10px;
  border-radius: $border-radius-large;
}

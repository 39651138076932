@import '@alkem/react-styles/src/vars';

.GDSNImportMappingsItem {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 6px;
  border: 1px solid $gray-light;
  margin-bottom: 10px;
  border-radius: $border-radius-large;
}

.GDSNImportMappingItem__header__opened {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  border-bottom: 1px solid $gray-light;
  margin-bottom: 10px;
  padding-bottom: 6px;
}

.GDSNImportMappingItem__header__closed {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.GDSNImportMappingsItem__buttonsContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  flex: 1;
}

.GDSNImportMappingItem_validity {
  cursor: pointer;
}

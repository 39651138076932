.CommandPalette__SearchBarInput {
  border-color: black;
  border-radius: 5px;
  border-style: solid;
  border-width: 2px;
  width: 100%;
  height: 50px;
  position: relative;

  & > input {
    border: None;
    border-radius: inherit;
    width: 100%;
    height: 100%;
    position: absolute;
    background-color: transparent;
  }
}

.CommandPalette__Container {
  top: 20%;
  left: 25%;
  position: fixed;
  z-index: 9999999;
  width: 50%;
  border-color: whitesmoke;
  border-radius: 5px;
  border-style: solid;
  padding: 10px;
  background-color: white;
}

.CommandPalette__Panel {
  max-height: 400px;
  overflow-y: auto;
}

.CommandPalette__BackgroundScreen {
  width: 100%;
  height: 100%;
  background-color: black;
  position: fixed;
  opacity: 25%;
  top: 0;
  left: 0;
  fill: black;
}

.CommandPalette__AutoCompleteSearchBarInput {
  z-index: -1;
  color: darkgray;
  cursor: none;
}

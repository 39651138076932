.GDSNExportMappings__xpathContainer {
  margin-bottom: 10px;
  pointer-events: none;
  opacity: 0.6;
}

.GDSNExportMappings_buttonsContainer {
  display: flex;
  justify-content: flex-end;
  padding-bottom: 6px;
}

.GDSNExportMappings__xpathContainer--disabled {
  flex: 1;
}

@import '@alkem/react-styles/src/vars';

.ApplicableKinds {
  display: flex;
  flex-direction: row;
  align-items: baseline;
  justify-content: space-between;
  margin-top: $half-margin-base;
  margin-bottom: $half-margin-base;
  > .Button {
    margin-left: $half-margin-base-horizontal;
  }
  &:not(:last-child) {
    margin-right: 110px;
  }
}

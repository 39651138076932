@import '@alkem/react-styles/src/vars';

.ProductReviewView__Title {
  font-size: 18px;
  font-weight: bold;
}

.ProductReviewView__DateConnector {
  height: 100px;
}

.ProductReviewView__Date {
  font-size: 36px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.ProductReviewView__Timestamp {
  display: flex;
  align-items: center;
  justify-content: right;
  height: 100%;
}

.ProductReviewView__Text {
  display: flex;
  align-items: center;
}

.ProductReviewView__TextIcon {
  padding: 8px;
}

.ProductReviewView__TextContent {
  background-color: black;
  border-radius: 8px;
  padding: 16px;
  color: white;
  font-family: 'Roboto Mono', monospace;
  width: 95%;
}

.ProductReviewView__Autoaccept {
  background-color: $brand-highlight;
}

.ProductReviewView__AutoAcceptConnector {
  height: 100px;
}

.ProductReviewView__DateItem {
  position: sticky !important;
  top: 40px;
  background-color: $gray-lighter;
  z-index: 999;
}

.ProductReviewView__TimelineEvent {
  display: flex;
}

.ProductReviewView__TimelineEvent:first-of-type
  > .ProductReviewView__TimelineDate {
  margin: 0;
}

.ProductReviewView__TimelineDate {
  width: 20%;
  margin: auto 0;
}

.ProductReviewView__TimelineIconWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.ProductReviewView__TimelineIcon {
  margin: 8px 16px;
  height: 44px;
  width: 44px;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: $brand-white;
}

.ProductReviewView__Icon {
  font-size: 24px;
  line-height: 1.1;
}

.ProductReviewView__TimelineSeparator {
  height: 25px;
  width: 2px;
  background-color: $gray-light;
  flex-grow: 1;
}

.ProductReviewView__TimelineSeparatorLong {
  height: 80px;
  width: 2px;
  background-color: $gray-light;
  flex-grow: 1;
}

.ProductReviewView__TimelineEventInfo {
  width: 65%;
  margin: auto 0;
  padding: 8px 0;
}

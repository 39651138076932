@import '@alkem/react-styles/src/vars';

ul.OrganizationPageInfo__list {
  list-style: none;
  padding: 0;
  margin: 0;

  li {
    display: flex;
    justify-content: space-between;
    line-height: 2rem;
  }
}

.OrganizationPageInfo__row {
  display: flex;
  + .OrganizationPageInfo__row {
    margin-top: 2px;
  }
}

.OrganizationPageInfo__label {
  width: 25%;
}

.OrganizationPageInfo__value {
  width: 75%;
  font-weight: 400;

  &--green {
    color: $brand-success;
  }

  &--red {
    color: $brand-danger;
  }

  &--bold {
    font-weight: 500;
  }

  .InputText__input {
    height: 2rem;
    line-height: 2rem;
  }
}

.OrganizationPageInfo__value__logo {
  width: 75%;
  overflow-x: auto;
}

@import '@alkem/react-styles/src/vars';
.ESContent__input {
  > span {
    margin-right: 5px;
  }
}

.ESContent_fieldSuggestion {
  padding: 3px;
  color: $brand-secondary;
  text-decoration: underline;
}

.ESContent_field {
  height: 32px;
  margin: 5px;
  padding: 5px;
  border: 1px solid $brand-primary;
  border-radius: 5px;
}

.ESContent__input__spinner {
  width: 20px;
  .Spinner {
    width: 17px;
  }
}

.ESContent__content {
  margin-top: 10px;
}

.ESContent__payloadContent {
  height: 500px;
}

.ESContent__AceEditor {
  margin-top: 1rem;
  width: 100%;
  height: 100%;
  font-size: 1rem;
}

.ESContent__filterSourceInclude {
  margin: 10px 0;
  display: flex;
  flex-wrap: wrap;
}

.ESContent__filterSourceIncludeElement {
  margin: 2px;
  padding: 5px;
  border: 1px solid $brand-primary;
  border-radius: 5px;
}

@import '@alkem/react-styles/src/vars';

.container {
  :global(.Tooltip.__react_component_tooltip) {
    overflow-wrap: break-word;
  }
}

.disabledContainer {
  pointer-events: none;
  opacity: 0.6;
}

.sectionTitle {
  margin-top: 1.25em;
  border-bottom: 1px solid $gray-light;
  font-weight: 500;
  margin-bottom: 10px;
  padding-bottom: 6px;
}

.messageContainer {
  display: flex;
  flex-direction: column;
}

.property:not(:last-child) {
  border-bottom: 1px dashed $gray-light;
}

.property {
  :global(.InputWithLabel) {
    margin: 0.25em;
    :global(.InputWithLabel__label) {
      label {
        text-align: right;
      }
    }
  }
}

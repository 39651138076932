.ProductNode {
  flex: 1;
}

.ProductNode__section {
  margin-top: 1rem;
}

.ProductNode__section__results {
  .btn {
    width: 100%;
  }
}

.ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
}

.code-ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
}

.ProductNode__io__result {
  display: flex;
  align-items: center;
  overflow: hidden;
  flex: 1;
}

.ProductNode__title {
  .Button {
    margin-left: 0.5rem;
  }
}

.ProductNode__tooltipList {
  padding-left: 0;
}

.ProductNode__githubLink {
  display: inline-block;
}

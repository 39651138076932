@import '@alkem/react-styles/src/vars';

.ReferentialsManager__content {
  margin: 30px 20px;
  height: calc(100vh - 140px - 60px);
}

.Referential__header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: $padding-large-vertical $padding-base-horizontal;
  background-color: $brand-white;
  border: 1px solid $gray-border;
  > .Button {
    margin-left: $margin-base-horizontal;
  }
}

.ReferentialDetail__Header {
  display: flex;
  justify-content: space-between;
  padding: $padding-large-vertical $padding-base-horizontal;
  background-color: $brand-white;
  border-left: 1px solid $gray-border;
  border-right: 1px solid $gray-border;
}

.ReferentialDetail__actions {
  display: flex;
  > .Button + .Button {
    margin-left: $half-margin-base-horizontal;
  }
}

.ReferentialsManager__btnGroup {
  display: flex;
  justify-content: center;

  .btn-secondary.selected {
    box-shadow: inset 0 0 1px 0 $brand-secondary;
    border: 1px solid $brand-secondary;
    background-color: rgba($brand-primary, 0.2);
    color: $brand-secondary;
    z-index: 2;
    font-weight: 400;
  }
  .btn:first-child {
    margin-right: 0;
  }
}

.ReferentialDetail__content {
  padding: $padding-large-vertical $padding-base-horizontal;
  background-color: $brand-white;
  border: 1px solid $gray-border;
}

.LinkedAttributes__header {
  .Collapsible {
    flex-basis: 100%;
  }

  .Collapsible__header {
    margin-bottom: 1rem;
  }
}

.LinkedAttributes__content {
  .Collapsible__content {
    display: flex;
    flex-wrap: wrap;
    gap: 0.2rem;
  }
}

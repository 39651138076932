@import 'dashboards/shared/styles/variables';

.UIImportFilters {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 20px;
  height: 100%;
  background-color: $white;
  border-radius: 20px;
  overflow-y: auto;
  &::-webkit-scrollbar {
    display: none;
  }

  &__section {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
}

@import '@alkem/react-styles/src/vars';

.DiscoverServices {
  background-color: $brand-white;
  padding: 10px;
}

.DiscoverService__extraRoutes {
  margin-top: 20px;
}

.DiscoverRoutes__filter {
  margin-top: 10px;
  label {
    margin-left: 20px;
  }
}

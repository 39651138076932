.GDSNCIHWModal {
  width: 850px;
  height: 500px;
}

.GDSNCIHWModal__Form {
  .GDSNCIHWModal__Line {
    padding-top: 5px;
    padding-bottom: 5px;
  }
}

.GDSNCIHWModalHandler__Right {
  text-align: right;
}

.GDSNCIHWModalHandler__AddHandler {
  text-align: center;
  margin-top: 10px;
}

.GDSNCIHWModal__LineRemoved {
  background-color: #ffd4d4;
}

.GDSNCIHWModal_LinkButton {
  margin-top: 11px;
}

.GDSNCIHWModal__Hr {
  border-top: 1px dashed #ccc;
  margin-left: 45px;
  margin-right: 45px;
}

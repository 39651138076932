.PayloadModal {
  height: 100%;

  .PayloadModal__Body {
    height: 100%;
    display: flex;
    flex-flow: column;
  }

  .PayloadModal__Content {
    margin-top: 1rem;
    width: 100%;
    height: 100%;
    font-size: 1rem;
  }
}

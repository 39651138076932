.IndexerQueue__reindexBtn {
  float: right;
}

.ModalOrganizationReindexRow {
  padding-bottom: 10px;
}

.ModalOrganizationReindexRowWarning {
  background-color: #ff6565;
  padding: 10px;
}

.IndexerQueue {
  display: flex;
  flex-direction: row;
}

.IndexerQueue__filters {
  width: 25em;
  margin: 1em;
}

.IndexerQueue__content {
  flex-grow: 1;
  margin: 1em;
  display: flex;
  flex-direction: column;
}

.IndexerQueue__status {
  align-self: center;
}

@import '@alkem/react-styles/src/vars';

.DiscoverRoute {
  padding: 10px 20px;
  border: 1px solid $brand-secondary;
  border-radius: $border-radius-small;
  margin-top: 10px;
}

.DiscoverRoute__header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.DiscoverRoute__label {
  display: inline-flex;
  align-items: center;
}

.DiscoverRoute__name {
  color: $brand-primary;
  margin-bottom: 0;
}

.DiscoverRoute__deprecated {
  padding: 3px 5px;
  color: $brand-white;
  background-color: $brand-danger;
  border-radius: $border-radius-small;
  margin-left: 10px;
}

.DiscoverRoute__authorization {
  padding: 3px 5px;
  color: $brand-white;
  background-color: $brand-primary;
  border-radius: $border-radius-small;
  margin-left: 10px;
}

.DiscoverRoute__rpc {
  padding: 3px 5px;
  color: $brand-white;
  background-color: $brand-secondary;
  border-radius: $border-radius-small;
  margin-left: 10px;
}

.DiscoverRoute__links {
  font-size: x-large;
  i {
    color: $brand-primary;
    margin-left: 5px;
  }
}

.DiscoverRoute__doc {
  white-space: pre;
}

.DiscoverRoute__protocol {
  margin-top: 5px;
}

.DiscoverRoute_runnable {
  margin-top: 1em;
  border-top: 1px solid $brand-secondary;
  padding: 1em;
}

.DiscoverRoute_runnable__desc {
  margin-bottom: 1em;
}

.DiscoverRoute_runnable__params {
  display: flex;

  .params_bloc {
    width: 100%;
    margin-right: 0.5em;
  }
}

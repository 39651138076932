@import '@alkem/react-styles/src/vars';

.RuleSetModal {
  margin-left: 0;

  .RuleSetModal__body {
    max-width: none;
  }

  .RuleSetModal__modalBody {
    .RuleSetModal-deadline {
      align-items: flex-end;

      &.RuleSetModal-deadline-dynamic {
        justify-content: space-between;
      }

      .RuleSetModal-deadline-delta {
        display: flex;
        align-items: flex-end;
      }

      // css on date picker
      .DateInputContainer {
        position: relative;
      }

      .DateFiler__calendar {
        width: 100%;
      }

      .DateRangePicker {
        width: 100%;
      }

      .DateRangePicker__Month {
        width: 100%;
        margin: 0;
      }

      .DateRangePicker__PaginationArrow--previous {
        left: 0;
      }

      .DateRangePicker__PaginationArrow--next {
        right: 0;
      }
    }

    > * + * {
      margin-top: $half-margin-base;
    }

    .DateFilter__wrapped {
      margin: 0;
    }
  }
}

.DeleteRulesetValidationModal__header.Modal__header {
  background-color: $brand-danger;
}

.DeleteRulesetValidationModal__footer .Modal__footerConfirmButton {
  background-color: $brand-danger;
  border-color: $brand-danger;

  &:hover {
    border-color: darken($brand-danger, 15%);
    background-color: darken($brand-danger, 15%);
  }
  &.disabled:hover {
    border-color: $brand-danger;
    background-color: $brand-danger;
  }
}

.DeleteRulesetValidationModal__footer .Modal__footerCloseButton {
  color: $brand-danger;
}

.DeleteRulesetValidationModal__modalBodyTextDanger {
  color: $brand-danger;
}

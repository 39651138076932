@import '@alkem/react-styles/src/vars';

.disabledContainer,
.disabledCategoryPropertyContainer {
  pointer-events: none;
  opacity: 0.6;
}

.container {
  :global(.InputWithLabel) {
    margin-top: 0.25em;
    .errorInput {
      :global(.Select__inputbox) {
        border-color: $alk-red;
      }
    }
  }
}

@import '@alkem/react-styles/src/vars';

.OrganizationGroupsList {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}

.OrganizationGroupsList__add {
  flex-basis: 20%;
  padding: $padding-base-vertical $padding-base-horizontal;
}

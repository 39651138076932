@import '@alkem/react-styles/src/vars';

$row-height-small: 32px;
$row-height: 64px;

.GDSNRCIListRowWrapper {
  margin: $padding-small-vertical 0;
  width: 100%;
  margin-left: 10px;
  margin-right: 10px;
  font-size: $font-size-small;

  .GDSNRCIListRow {
    cursor: auto;
    width: 100%;

    background-color: white;
    border: 1px solid $gray-border;
    height: $row-height;
    align-items: center;

    .col-xs-2 {
      padding-left: 5px;
    }

    &:hover {
      background-color: $alk-background-hover;
    }

    &.active {
      background-color: $brand-primary-active;
      color: white;
    }
  }

  .GDSNRCIListRow_mini {
    height: $row-height-small;
  }
}

.GDSNRCIListRow_PadTip {
  margin-right: 5px;
}

.GDSNRCIListRow_smally {
  font-size: $font-size-small;
}

.GDSNRCIListRow_boldy {
  font-weight: bolder;
}

.GDSNRCIListRow_actions {
  float: right;
}

.GDSNRCIListDetailsIconDoc {
  cursor: pointer;

  .hidden {
    visibility: hidden;
  }
}

.GDSNRCIListDetailsIcon {
  font-size: 24px;
  cursor: pointer;

  .hidden {
    visibility: hidden;
  }
}

@use 'sass:math';

@import '@alkem/react-styles/src/vars';
@import 'react-daterange-picker/src/css/react-calendar';

$date-height: 30px;

.InputDate__item,
.InputDate__item--center {
  align-items: center;
  display: flex;
}

.InputDate__item--center {
  border: 1px solid $input-border-color;
  background-color: $brand-white;
}

.InputDate__item--readOnly {
  border: 0;
  background-color: $readonly-bg;
}

.InputDate__itemLabel {
  width: 200px;
  border: none;
  padding-left: $padding-base-horizontal;
  background: #fff;
}

.InputDate__itemLabel--full {
  width: 175px;
}

.InputDate__itemLabel--readOnly {
  width: 170px;
  background-color: $readonly-bg;
  color: $readonly-fg;
}

.InputDate__itemLabel--small {
  width: 125px;
}

.InputDate__itemLabel--small.InputDate__itemLabel--full {
  width: 100px;
}

.InputDate__itemLabel--small.InputDate__itemLabel--readOnly {
  width: 95px;
}

.InputDate__itemIcon {
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  font-size: 20px;
  width: $date-height;
  height: $date-height;
  background: $brand-primary;
  border: 0;
  color: #fff;
  cursor: pointer;
}

.InputDate__itemIcon--active:hover {
  background: $brand-primary-btn-hover;
}

.InputDate__itemIcon--readOnly {
  background-color: $readonly-bg-darkest;
  cursor: default;
}

.InputDate__itemCalendar {
  display: none;
  width: 315px;
  position: absolute;
  top: calc(100% - 1px);
  left: 0;
  right: 0;
  padding: 0;
  margin: 0;
  z-index: 1;
  border: 1px solid $brand-primary;
  background: white;

  &.InputDate__itemCalendar--opened {
    display: block;
  }
}

.DateRangePicker__WeekdayHeading {
  font-weight: 300;
  cursor: default;
}

.DateRangePicker__CalendarSelection {
  background-color: $brand-primary;
  border-color: $brand-primary;
}

.InputDate__deleteIcon {
  width: 25px;
  cursor: pointer;
}
.InputDate__input {
  padding-top: $field-padding-vertical;
  padding-bottom: $field-padding-vertical;
}

.InputDate__label {
  align-items: center;
  display: flex;
  height: $field-height-base;
  justify-content: flex-end;
  margin-bottom: 0;
  text-align: right;
}

.InputDate__help {
  margin-left: math.div($margin-base-horizontal, 3);
  top: 0;
}

@import '@alkem/react-styles/src/vars';

.Button.OrganizationListFilters__createButton {
  width: 100%;
  color: $brand-secondary;
  background-color: hsla(0, 0%, 100%, 0);
  border-color: $brand-secondary;

  &:hover {
    background-color: $brand-secondary;
    border-color: transparent;
    color: #fff;
  }
}

.OrganizationListFilters__createContent {
  display: flex;
  align-items: center;
}

.OrganizationListFilters__createIcon {
  font-size: 1.8em;
}

.OrganizationListFilters__createLabel {
  padding-left: 0.5rem;
}

#filter-organizationlist-filter-status-checkbox-status-0,
#filter-organizationlist-filter-status-checkbox-status-1,
#filter-organizationlist-filter-status-checkbox-status-3 {
  & > .ReactUiCheckbox__label {
    display: inline-flex;
    align-items: center;
    &:before {
      display: inline-block;
      width: 5px;
      height: 1em;
      margin-right: 0.25rem;
      content: '';
    }
  }
}

#filter-organizationlist-filter-status-checkbox-status-0 {
  & > .ReactUiCheckbox__label:before {
    background-color: $brand-danger;
  }
}
#filter-organizationlist-filter-status-checkbox-status-1 {
  & > .ReactUiCheckbox__label:before {
    background-color: $brand-success;
  }
}
#filter-organizationlist-filter-status-checkbox-status-3 {
  & > .ReactUiCheckbox__label:before {
    background-color: #000;
  }
}

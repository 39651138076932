@import '@alkem/react-styles/src/vars';

.OrganizationPageSettingMatrixRow {
  display: flex;
  justify-content: space-around;

  .OrganizationPageSettingMatrixCell {
    margin: 5px 15px 5px 15px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    flex-basis: 100%;
    text-align: center;

    input {
      border: 1px solid #b8b8b8;
      height: 30px;
      padding-left: 5px;
      padding-right: 5px;
    }

    .mdi-delete {
      cursor: pointer;
    }
  }

  .OrganizationPageSettingMatrixCell.OrganizationPageSettingMatrixDelete {
    width: 10px;
    flex-basis: 10px;
  }
}

.OrganizationPageSettingMatrixAdd {
  margin-top: 5px;
  margin-bottom: 5px;
}

.OrganizationPageSettingMatrixHelp {
  background-color: $alk-background-hover;
  padding: 0 5px 0 5px;
}

.OrganizationPageSettingMatrixHeader {
  .OrganizationPageSettingMatrixCell {
    .mdi-delete {
      visibility: hidden;
      cursor: pointer;
    }
  }
}

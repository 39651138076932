.OrganizationPageSupplierDirectory__content {
  padding-left: 1rem;
}

.OrganizationPageSupplierDirectory__subtitle {
  margin: 0.5rem 0;
}

.OrganizationPageSupplierDirectory__content .OrganizationPageSettingsCheckbox {
  margin: 0.5rem 0;
}

@import '@alkem/react-styles/src/vars';

.BrandClaimRow {
  display: flex;
}

.BrandClaimHeaderRow {
  &,
  &:hover {
    background: none;
    border: none;
    height: 25px;
  }
}

.BrandClaimRow__header {
  font-weight: 300;
  color: $gray-medium;
}

.BrandClaimRow__brand {
  padding-left: 1rem;
  font-weight: 400;
  flex: 3;
  overflow: hidden;
}

.BrandClaimRow__username {
  flex: 3;
  overflow: hidden;
}

.BrandClaimRow__type {
  flex: 1;
  text-align: center;
  display: flex;
  justify-content: center;
}

.BrandClaimRow__status {
  flex: 1;
  text-align: center;
  display: flex;
  justify-content: center;
}

.BrandClaimRow__createdAt {
  flex: 1;
  text-align: center;
  justify-content: center;
}

.BrandClaimRow__updatedAt {
  flex: 1;
  text-align: center;
  justify-content: center;
}

.BrandClaimRow__actions {
  text-align: center;
  flex: 2;
  display: flex;
  justify-content: center;
}

.BrandClaimRow__button {
  &Accept {
    border-radius: 7px 0 0 7px;
  }
  &Refuse {
    border-radius: 0 7px 7px 0;
  }
}

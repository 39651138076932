.OrganizationCreateForm {
  width: 70vw;
}

.OrganizationCreateForm__line {
  display: flex;
  padding-bottom: 1rem;
  align-items: center;

  &.OrganizationCreateForm__line--settings {
    align-items: flex-start;
  }

  &.OrganizationCreateForm__line--invisible {
    visibility: hidden;
  }
}

.OrganizationCreateForm__label {
  flex: 1;
  padding-right: 1rem;
  text-align: right;
}

.OrganizationCreateForm__inputs {
  flex: 3;

  &.OrganizationCreateForm__inputs--settings {
    display: flex;
    flex-direction: column;
  }
}

.OrganizationCreateForm__input {
  display: flex;
}

.OrganizationCreateForm__input .HelpTooltip {
  margin-left: 5px;
}

.OrganizationCreateForm__textInput {
  width: 100%;
}

.OrganizationCreateForm__radio {
  & > span {
    padding-left: 0.5rem;
  }
}

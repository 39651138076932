@import '@alkem/react-styles/src/vars';

.row,
.rowDetail,
.rowDetailHeader,
.rowHeader {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.rowDetail {
  background-color: white;
  padding: 10px;
}

.rowDetailHeader {
  background-color: white;
  padding: 10px;
  border-bottom: 1px solid $gray-border;
}

.row {
  background-color: white;
  border-bottom: 1px solid $gray-border;
  min-height: 4em;
  margin-top: 0.5em;
}

.rowColumn {
  align-items: center;
  margin-left: 0.5em;
  margin-right: 0.5em;
  flex-basis: 15%;
  justify-content: right;
}

// Tags

.tag {
  padding: 5px;
  font-size: x-small;
  border-radius: 5px;
  margin: 5px;
  color: white;
}

.tagClickable {
  cursor: pointer;
}

.tagIcon {
  padding-left: 4px;
}

.colorSuccess {
  background-color: #48b071;
}

.colorPrimary {
  background-color: #2d72d2;
}

.colorBase {
  background-color: #6a6a6a;
}

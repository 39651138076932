.GDSNPCRModal__Form {
  .GDSNPCRModal__Line {
    padding-top: 5px;
    padding-bottom: 5px;
  }
}

.GDSNPCRModalHandler__Right {
  text-align: right;
}

.GDSNPCRModalHandler__AddHandler {
  text-align: center;
  margin-top: 10px;
}

.GDSNPCRModal__LineRemoved {
  background-color: #ffd4d4;
}

.GDSNPCRModal_LinkButton {
  margin-top: 11px;
}

.EnpointModal_CheckBoxAlign {
  margin-top: -5px;
}

.EnpointModal_SelectMargin {
  margin-top: 10px;
}

.GDSNPCRModal__Hr {
  border-top: 1px dashed #ccc;
  margin-left: 45px;
  margin-right: 45px;
}

@import 'dashboards/shared/styles/variables';

.UIImportReplayModal {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  padding: 20px;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 60vw;
  max-height: 90vh;
  overflow-y: auto;
  &::-webkit-scrollbar {
    display: none;
  }

  &__Section {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }

  &__Code {
    width: 100%;
    font-family: 'Roboto Mono', monospace;
    resize: none;
  }
}

@import '@alkem/react-styles/src/vars';

.ApplicationsDashboard__row {
  > * {
    flex: 1 0 0px;
  }

  > .ApplicationsDashboard__delete {
    flex: 0 0 20px;
  }
}

.TwinPanel__panel.Applications {
  min-width: 250px;
}

.ApplicationDetail__Header {
  width: 100%;
  display: inline-flex;
  .ApplicationDetail__Title {
    flex: 1 1 0;
    margin-top: 6px;
    margin-left: 5px;
    margin-bottom: 2px;
  }
}

.ApplicationsDashboard__content {
  margin: 30px 20px;
  height: calc(100vh - 140px - 60px);
  .btn + .btn {
    margin-left: $half-margin-base-horizontal;
  }
}

.ApplicationsDashboardView__panel--right {
  flex-grow: 1;
}

.ApplicationsDashboard__loading {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  .Spinner {
    margin-bottom: 1rem;
  }
}

.app-saving {
  opacity: 0;
  display: flex;
  align-items: center;
  margin-right: 20px;
  font-size: 1rem;

  animation: saving-response 0.2s 1;
  -webkit-animation: saving-response 0.2s 1;
  animation-fill-mode: forwards;

  animation-delay: 0.8s;
  -webkit-animation-delay: 0.8s;
  -webkit-animation-fill-mode: forwards;

  .Spinner {
    margin-top: 5px;
  }
}

.app-saved {
  display: flex;
  align-items: center;
  margin-right: 20px;
  font-size: 1rem;

  animation: saved-response 0.4s 1;
  -webkit-animation: saved-response 0.4s 1;
  animation-fill-mode: forwards;

  animation-delay: 3.2s;
  -webkit-animation-delay: 3.2s;
  -webkit-animation-fill-mode: forwards;

  .mdi {
    color: yellowgreen;
  }
}

.space-right {
  margin-right: 6px;
}

@keyframes saving-response {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@-webkit-keyframes saving-response {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes saved-response {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

@-webkit-keyframes saved-response {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

.app-left-panel-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

@import '@alkem/react-styles/src/vars';

.container {
  border: 1px solid $gray-light;
  margin-top: 1.25em;
  border-radius: $border-radius-large;
  :global(.InputWithLabel) {
    margin: 0.25em;
  }
}

.buttonContainer {
  text-align: right;
  padding: 0.25em;
  :global(.mdi) {
    cursor: pointer;
  }
}

@import '@alkem/react-styles/src/vars';

.ListPaginationInfo {
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
  padding: 0.4rem 0;
}

.ListPaginationInfo__background {
  position: absolute;
  top: 50%;
  left: 2px;
  right: 2px;
  height: 2px;
  margin-top: -1px;
  overflow: hidden;
  background-image: linear-gradient(to right, $gray-light 50%, transparent 0%);
  background-position: bottom;
  background-size: 4px 2px;
  background-repeat: repeat-x;
}

.ListPaginationInfo__content {
  display: flex;
  position: relative;
  height: 1.8rem;
  padding: 0 1rem;
  color: white;
  background: $alk-green;
  border-radius: 0.9rem;
  align-items: center;
}

.ListPaginationInfo__text {
  white-space: nowrap;
}

.ListPaginationInfo__circle {
  font-size: 0.5rem;
  padding: 0 0.5rem;
}

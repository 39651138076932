@import '@alkem/react-styles/src/vars';

.IndexerQueueRow,
.IndexerQueueHeader {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 0.5em;
}

.IndexerQueue__header {
  min-height: 3em;
}

.IndexerQueueRow {
  background-color: white;
  border: 1px solid $gray-border;
  min-height: 4em;
}

.IndexerQueueRow__column {
  display: flex;
  align-items: center;
  margin-left: 0.5em;
  margin-right: 0.5em;
}

.IndexerQueueRow__org {
  flex-basis: 40%;
}

.IndexerQueueRow__data {
  flex-basis: 15%;
  justify-content: right;
}

.IndexerQueueRow__exp {
  flex-grow: 1;
  justify-content: right;
}

.IndexerQueueRow__exp .Button {
  font-size: 2em;
  padding: 0;
  margin: 0;
  border: none;

  &:focus {
    outline: none;
  }
}

.IndexerQueue__refresh.Button {
  font-size: 1.2em;

  &:focus {
    outline: none;
  }
}

@import '@alkem/react-styles/src/vars';

.ProductWorkflowPlayground__meta {
  margin: 0.5em 1em;
  padding: 0.5rem 1rem;
  background: white;

  display: flex;
  align-items: center;
  justify-content: space-between;

  .Select {
    flex: 1;
  }

  .Button {
    margin-left: 0.5rem;
  }
}

.ProductWorkflowPlayground__panel {
  position: absolute;
  top: 260px;
  right: 0;
  bottom: 8px;
  overflow-y: scroll;

  background: white;
  padding: 0.5rem 1rem;

  box-shadow: 0 0 8px 0px rgba(0, 0, 0, 0.2);

  width: 600px;
  &--collapsed {
    width: 60px;
    padding: 1rem;
    right: 2rem;
    flex-direction: row;
    writing-mode: vertical-rl;
    text-orientation: sideways;
    cursor: pointer;
  }

  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.ProductWorkflowPlayground__panel__hideButton .btn {
  width: 100%;
}

// Set height 100% to all
#play-ground {
  display: flex;
  flex-direction: column;

  .HeaderLayout {
    margin: 0;
  }
}

.ProductWorkflowPlayground__content {
  flex: 1;
}

.ProductWorkflowPlayground__nodes {
  padding: 1em 2em;
}

.card.ProductWorkflowPlayground__node {
  border-radius: $border-radius-small;
  padding: 1rem;
}

#product-graph {
  width: calc(100% - 65px);
  height: calc(100vh - 265px); // FULL YOLOOOOO
}

#product-graph svg {
  width: 100%;
  height: 100%;
}

.code-no-bg {
  background: transparent;
  color: $text-color;
}

.ProductWorkflowPlayground__nodes__search {
  display: flex;
  padding: 0.5rem;
  align-items: center;

  .InputField {
    flex: 1;
    margin-right: 0.2rem;
  }

  .SimpleSelect {
    width: 150px;
    margin-left: 0.4rem;
  }
}

.ProductWorkflowPlayground__header__actions__graph {
  display: flex;
  align-items: center;
}

.ProductWorkflowPlayground--hidden {
  display: none;
}

.ProductWorkflowPlayground__separator {
  height: 12px;
  margin-left: 0.5rem;
  border-left: 2px solid $gray-light;
}

.ProductWorkflowPlayground__header {
  display: flex;
  justify-content: flex-end;
}

.ApiSelect {
  background: white;

  display: flex;
  align-items: center;

  padding: 0.5rem;
  width: 50%;

  border-radius: $border-radius-small;

  .SimpleSelect {
    flex: 1;
    margin-left: 0.1rem;
  }
}

@import '@alkem/react-styles/src/vars';

.buttonContainer {
  text-align: right;
  padding: 0.25em;
  :global(.mdi) {
    color: $brand-primary;
    cursor: pointer;
  }
}

.container {
  border: 1px solid $gray-light;
  margin-top: 1.25em;
  border-radius: $border-radius-large;
  :global(.InputWithLabel) {
    margin: 0.25em;
    .errorInput {
      :global(.TurboSelect__control) {
        border-color: $alk-red;
      }
    }
  }
}

.nameContainer {
  display: flex;
  width: 100%;
  :global(.InputText) {
    width: 100%;
    :global(.InputField__input) {
      flex: 1;
      :global(.InputText__input) {
        padding: 5px 10px;
      }
    }
  }
  :global(.mdi) {
    margin-left: 10px;
    color: $brand-primary;
    cursor: pointer;
    align-self: center;
  }
}

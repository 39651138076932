@use 'sass:math';
@import '@alkem/react-styles/src/vars';

.OrganizationGroupField {
  .card {
    padding: 1rem;
  }
}

.OrganizationGroupField__AddField {
  margin-top: 1rem;
}

.OrganizationGroupField__AddFieldGroup {
  align-self: center;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;

  .OrganizationGroupField__AddFieldGroup--name {
    flex-grow: 1;
  }
}

.OrganizationGroupField__FieldGroup {
  .OrganizationGroupField__FieldGroup--title {
    display: flex;
    width: fit-content;
    margin: auto;

    > div {
      padding: 0 math.div($padding-base-horizontal, 2);
    }
  }

  .OrganizationGroupField__FieldGroup--name {
    .form-control {
      font-size: 2rem;
      font-weight: bold;
    }
  }
}

.OrganizationGroupField__Field {
  display: flex;
  flex-direction: row;

  > div {
    padding: 5px;

    &:first-child {
      padding-left: 0;
    }
    &:last-child {
      padding-right: 0;
    }
  }
  .OrganizationGroupField__FieldRoot {
    width: 200px;
  }
  .OrganizationGroupField__FieldName {
    flex-grow: 1;
    min-width: 250px;
  }
  .OrganizationGroupField__FieldOnCreation,
  .OrganizationGroupField__FieldOnUpdate {
    width: 160px;
    text-align: center;
    display: flex;
    align-self: center;
    justify-content: center;
  }
  .OrganizationGroupField__FieldDelete {
    width: 80px;
    text-align: right;
    display: flex;
    align-self: center;
    justify-content: flex-end;
  }
}

.OrganizationGroupField__Search {
  background-color: $brand-white;
}

.OrganizationGroupField__Default {
  font-size: 1rem;
  color: #fff;
  border-radius: $border-radius-very-large;
  text-align: center;
  margin: auto;
  background-color: $brand-secondary;
  border: 1px solid $brand-secondary;
}

@import '@alkem/react-styles/src/vars';

.container {
  :global(.InputWithLabel) {
    margin-top: 0.25em;
    .errorInput {
      :global(.TurboSelect__control) {
        border-color: $alk-red;
      }
    }
  }
}

.disabledContainer {
  pointer-events: none;
  opacity: 0.6;
}

.textField {
  margin-bottom: 0.25em;
}

@import 'dashboards/shared/styles/variables';

.UIImportTable {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 20px;
  overflow-y: auto;
  height: 100%;
  background-color: $white;
  border-radius: 20px;
  font-family: inherit;
  font-size: $font-medium;

  &__Actions {
    display: flex;
    justify-content: flex-end;
    gap: 20px;
  }

  &__Columns {
    display: flex;
    flex-direction: column;

    &__User,
    &__Time {
      font-size: $font-small;
      color: $gray;
    }

    &__Actions {
      width: 100%;
      display: flex;
      align-items: center;
      gap: 4px;

      & > i {
        background-color: $white;
        width: 40px;
        height: 40px;
        border-radius: 50%;
        border: 1px solid $PRIMARY;
        cursor: pointer;
        color: $PRIMARY;
        font-size: 24px;
        display: grid;
        align-items: center;
        justify-content: center;

        &:hover {
          background-color: $PRIMARY;
          color: $white;
        }
      }
    }
  }
}

@import '@alkem/react-styles/src/vars';

.FieldForm .InputWithLabel {
  margin-top: 0.25em;
}

.FieldForm__sectionTitle {
  margin-top: 1.25em;
  border-bottom: 1px solid $gray-light;
  font-weight: 500;
}

.FieldForm__checkboxLabel {
  height: 1em;
}

.FieldForm__child {
  margin-top: 0.25em;
}

.FieldForm__addChild {
  margin-top: 0.25em;
}

@import '@alkem/react-styles/src/vars';

.AddPicture {
  min-height: 100%;
  display: flex;
  flex-direction: column;
  .Raguel {
    padding: 1em;
  }
}

.AddPicture__dropzone {
  width: 100%;
  padding: 15px;
  border: 1px $brand-primary dashed;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  flex-grow: 1;

  & > div {
    display: inline-block;
  }

  &.AddPicture__dropzone--active {
    background-color: $brand-primary;
    border-color: $brand-secondary;
  }
}

.Dropzone__label {
  color: $brand-primary;
  font-size: $font-size-large;
}

.Dropzone__button {
  display: flex;
  align-items: center;
  justify-content: center;
  & > span {
    color: $brand-primary;
    font-size: $font-size-large;
  }
  & > button {
    margin-left: $half-margin-base-horizontal;
    font-size: $font-size-large;
  }
}

.AddPicture__container {
  margin-top: $margin-base;

  &:first-child {
    margin-top: 0;
  }
}

.AddPicture__imageContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.AddPicture__image {
  max-width: 100%;
  max-height: 300px;
}

.AddPicture__packshotCheckbox {
  text-align: center;
  margin-top: $half-margin-base;
}

.AddPicture__imageContainer_metadata {
  margin-top: $half-margin-base;
}

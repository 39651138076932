@import '@alkem/react-styles/src/vars';

.EventBindingsOverview {
  display: flex;
  flex-direction: row;
  justify-content: justify-content;
  padding: 5px;
}

.EventBindingsOverviewName {
  padding: 10px;
  border-left: solid 1px;
}

.EventBindingsOverviewQueues {
  list-style: none;
  border-left: 1px solid;
}

.EventBindingsOverviewQueue {
  padding-left: 10px;
  border-left: 8px solid grey;
}

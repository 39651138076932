$font-large: 16px;
$font-medium: 14px;
$font-small: 12px;

$font-semibold: 500;

$white: #ffffff;
$gray-lighter: #f0f0f0;
$gray-light: #cccccc;
$gray: #888;

$PRIMARY: #166a83;
$INDIGO: #3f51b5;
$INDIGO_LIGHT: #6573c3;
$INDIGO_DARK: #2c387e;
$DEEP_PURPLE: #673ab7;
$DEEP_PURPLE_LIGHT: #8561c5;
$DEEP_PURPLE_DARK: #482880;
$TEAL: #009688;
$TEAL_LIGHT: #33ab9f;
$TEAL_DARK: #00695f;
$PURPLE: #9c27b0;
$PURPLE_LIGHT: #af52bf;
$PURPLE_DARK: #6d1b7b;
$BLUE: #2196f3;
$BLUE_LIGHT: #4dabf5;
$BLUE_DARK: #1769aa;
$RED: #f44336;
$RED_LIGHT: #f6685e;
$RED_DARK: #aa2e25;
$AMBER: #ffc107;
$AMBER_LIGHT: #ffcd38;
$AMBER_DARK: #b28704;

@import '@alkem/react-styles/src/vars';

$row-height-small: 48px;
$row-height: 64px;

.GDSNPSRListRowWrapper {
  margin: $padding-small-vertical 0;
  width: 100%;
  font-size: $font-size-small;

  .GDSNPSRListRow {
    cursor: auto;
    width: 100%;

    background-color: white;
    border: 1px solid $gray-border;
    min-height: $row-height;
    align-items: center;

    .col-xs-2 {
      padding-left: 5px;
    }

    &:hover {
      background-color: $alk-background-hover;
    }

    &.active {
      background-color: $brand-primary-active;
      color: white;
    }
  }

  .GDSNPSRListRow_mini {
    min-height: $row-height-small;
    margin-left: 5px;
    width: 100%;
  }
}

.GDSNPSRListRow_PadTip {
  margin-right: 5px;
}

.GDSNPSRListRow_smally {
  font-size: $font-size-small;
}

.GDSNPSRListRow_boldy {
  font-weight: bolder;
}

.GDSNPSRListRow_actions {
  float: right;
  display: flex;
}

.GDSNPSRListDetailsIconDoc {
  cursor: pointer;

  .hidden {
    visibility: hidden;
  }
}

.GDSNPSRListDetailsPSRAck {
  font-size: 10px;
  padding: 1px 5px;
}

.GDSNPSRListDetailsIcon {
  font-size: 24px;
  cursor: pointer;

  .hidden {
    visibility: hidden;
  }
}

.GDSNPSRColBL_mini {
  border-left: dotted 2px grey;
}

.GDSNPSRListRowCenter {
  text-align: center;
  font-weight: bolder;
}

.GDSNPSRListDetailsIconDoc_green {
  color: green;
}

.GDSNPSRListDetailsIconDoc_red {
  color: red;
}

@import '@alkem/react-styles/src/vars';

.FieldTechnicalInfo__nameError {
  background: $brand-danger-hover;
  position: relative;
  margin-top: 3px;
  padding: $padding-small-vertical $padding-small-horizontal;
}

.FieldTechnicalInfo__nameError::before {
  content: '';
  margin-left: -3px;
  border-left: 3px solid $brand-danger;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
}

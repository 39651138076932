@import '@alkem/react-styles/src/vars';

.ImportModalRange__dropzone {
  width: 100%;
  height: 100%;
  padding: 100px 15px;
  border: 1px $brand-primary dashed;
  display: flex;
  align-items: center;
  justify-content: center;

  &.ImportFile__dropzone--active {
    background-color: $brand-primary;
    border-color: $brand-secondary;
  }
}

.ImportModalRange__label {
  cursor: pointer;
  &:hover {
    color: $brand-primary-hover;
  }
}

.ImportModalRange__params {
  margin-top: 20px;
  padding-right: 5px;
}

@import '@alkem/react-styles/src/vars';

.GDSNImportMappingsItemAutomaticProperties__sectionTitle {
  margin-top: 1.25em;
  border-bottom: 1px solid $gray-light;
  font-weight: 500;
}

.GDSNImportMappingsItemAutomaticProperties .InputWithLabel {
  margin-top: 0.25em;
}

@import '@alkem/react-styles/src/vars';

.HomeView__wrapper {
  margin-bottom: $half-margin-base;
}

.HomeView__card {
  position: relative;
  display: flex;
  align-items: stretch;
  min-height: 4rem;
  padding-left: 1rem;
  border: 1px solid #ccc;
  background: #fff;
  font-size: 1.5rem;
}

.HomeView__fav,
.HomeView__link {
  display: flex;
  align-items: center;
}

.HomeView__link {
  flex: 1;
}

.HomeView__fav {
  padding: 0 1rem;
  color: $gray-slightly-lighter;
  font-size: 2rem;

  &:hover {
    color: $brand-primary;
  }

  &.HomeView__fav--select {
    color: $brand-highlight;
    &:hover {
      color: $brand-primary;
    }
  }
}

.HomeView__dashboards {
  margin-top: 1rem;

  [data-for='home-favorites-help'] {
    font-size: 1.25rem;
  }
}

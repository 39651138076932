@import '@alkem/react-styles/src/vars';

.GLNClaimRow {
  display: flex;
}

.GLNClaimHeaderRow {
  &,
  &:hover {
    background: none;
    border: none;
    height: 25px;
  }
}

.GLNClaimRow__header {
  font-weight: 300;
  color: $gray-medium;
}

.GLNClaimRow__organization {
  padding-left: 1rem;
  font-weight: 400;
  flex: 2;
  overflow: hidden;
}

.GLNClaimRow__gln {
  font-weight: 400;
  flex: 2;
  overflow: hidden;
}

.GLNClaimRow__data {
  flex: 2;
  overflow: hidden;

  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: normal;

  &BasicInfos {
    display: flex;
    align-items: center;
  }

  &Country {
    background-color: $brand-primary;
    color: white;
    font-weight: 400;
    font-size: small;
    padding: 2px 4px;
    margin-right: 3px;
    border-radius: 5px;
  }

  &Contact {
    width: 100%;
    overflow: hidden;
    font-style: italic;
  }
}

.GLNClaimRow__username {
  flex: 2;
  overflow: hidden;
}

.GLNClaimRow__status {
  flex: 1;
  justify-content: center;
}

.GLNClaimRow__createdAt {
  flex: 1;
  text-align: center;
  justify-content: center;
}

.GLNClaimRow__updatedAt {
  flex: 1;
  text-align: center;
  justify-content: center;
}

.GLNClaimRow__actions {
  text-align: center;
  flex: 2;
  display: flex;
  justify-content: center;
}

.GLNClaimRow__button {
  &Accept {
    border-radius: 7px 0 0 7px;
  }
  &Refuse {
    border-radius: 0 7px 7px 0;
  }
}

.GLNCLaimRow__Edit {
  cursor: pointer;

  .hidden {
    visibility: hidden;
  }
}

.GLNClaimRowName_Action {
  padding-right: 10px;
}

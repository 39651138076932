.OrganizationPageSettingsCheckbox {
  display: flex;
  align-items: center;
}

.OrganizationPageSettingsCheckbox_checkbox {
  display: flex;
  > * {
    margin: 0;
  }
}

.OrganizationPageSettingsCheckbox_label {
  padding-left: 0.5rem;
}

.OrganizationPageSettingsCheckbox_help {
  padding-left: 0.5rem;
}

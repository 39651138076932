@import '@alkem/react-styles/src/vars';

.FieldsPage__body {
  overflow-x: hidden;
}

.FieldsPage__title {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.FieldsPage__button {
  margin-right: $half-margin-base-horizontal;
}

.FieldsPage__content {
  margin: $margin-base $margin-base-horizontal;
  height: calc(99vh - #{$large-header-height} - #{$margin-base * 2});
}

.FieldsPage__panel--left {
  width: 400px;
}

@media only screen and (max-width: 1300px) {
  .FieldsPage__panel--left {
    width: 360px;
  }
}

.FieldsPage__panel--right {
  flex-grow: 1;
}

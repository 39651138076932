@import '@alkem/react-styles/src/vars';
@import 'react-daterange-picker/src/css/react-calendar';

$date-height: 30px;

.GDSNImportMappingsItemDateSelectorInput__body {
  display: flex;
  justify-content: center;
}

.GDSNImportMappingsItemDateSelectorInput__wrapped {
  margin: 0px;
  display: flex;
  align-items: center;
  border: 1px solid $input-border-color;
  width: 100%;
}

.GDSNImportMappingsItemDateSelectorInput__input {
  width: 100%;
  border: none;
  padding-left: $padding-base-horizontal;
  background: #fff;
  outline: none;
}

.GDSNImportMappingsItemDateSelectorInput__calendarIcon {
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  font-size: 20px;
  width: $date-height;
  height: $date-height;
  background: $brand-primary;
  border: 0px;
  color: #fff;
  cursor: pointer;
}

.GDSNImportMappingsItemDateSelectorInput__calendar {
  display: block;
  width: 315px;
  position: absolute;
  padding: 0px;
  margin: 0px;
  z-index: 1;
  border: 1px solid $brand-primary;
  background: white;
}

.DateRangePicker__WeekdayHeading {
  font-weight: 300;
  cursor: default;
}

.DateRangePicker__CalendarSelection {
  background-color: $brand-primary;
  border-color: $brand-primary;
}

.GDSNImportMappingsItemDateSelector .InputWithLabel {
  margin-top: 0.25em;
}

.GDSNImportMappingsItemDateSelector {
  position: relative;
}

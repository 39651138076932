.UIImportListPage {
  height: calc(100vh - 40px);
  max-height: calc(100vh - 40px);
  width: 100%;
  margin-top: 40px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;

  &__Container {
    display: grid;
    grid-template-columns: 1fr 4fr;
    gap: 20px;
    height: calc(100% - 100px);
  }

  &__Section {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
}

@import '@alkem/react-styles/src/vars';

.body {
  overflow-x: hidden;
}

.content {
  margin: $margin-base $margin-base-horizontal;
  height: calc(99vh - #{$large-header-height} - #{$margin-base * 2});
}

.panelLeft {
  width: 400px;
  .TwinPanel__content {
    overflow: auto;
    height: 100%;
  }
  .elementSelectorContainer {
    height: 100%;
  }
}

@media only screen and (max-width: 1300px) {
  .panelLeft {
    width: 360px;
  }
}

.panelRight {
  flex-grow: 1;

  .TwinPanel__content {
    overflow: auto;
    height: 100%;
  }
}

.button {
  padding: 1px 2px 1px 2px;
}

.rightPanelTitle {
  display: flex;
  justify-content: space-between;
}

@import '@alkem/react-styles/src/vars';

.AFSMapperMenu {
  border-left: none;
}

.AFSPage__content {
  margin: $margin-base $margin-base-horizontal;
  height: calc(99vh - #{$large-header-height} - #{$margin-base * 2});
}

.AFSPage__panel--left {
  width: 400px;
}

.AFSPage__panel--right {
  flex-grow: 1;
}

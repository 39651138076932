@import 'dashboards/shared/styles/variables';

.UISwitch {
  display: flex;
  align-items: center;
  gap: 8px;

  &__Label {
    font-size: $font-medium;
  }

  &__Toggle {
    width: 2rem;
    height: 1rem;
    background-color: $gray-light;
    border-radius: 1rem;
    position: relative;
    cursor: pointer;
    transition: background-color 0.3s ease;

    &__On {
      background-color: $PRIMARY;
    }
  }

  &__Thumb {
    width: 0.6rem;
    height: 0.6rem;
    background-color: $white;
    border-radius: 50%;
    position: absolute;
    top: 0.2rem;
    left: 0.2rem;
    transition: left 0.3s ease;

    &__On {
      left: 1.2rem;
    }
  }
}

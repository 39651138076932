.EdaRmqReplayerQueueErrorContext {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  border-top: 1px dotted grey;
  border-bottom: 1px dotted grey;
  padding-top: 15px;
  padding-bottom: 15px;
}

.EdaRmqReplayerQueueErrorContext__elapsed {
  font-weight: lighter;
  padding-left: 10px;
}

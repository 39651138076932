@import '@alkem/react-styles/src/vars';

.FieldSuggestionValidationModal__failures {
  margin-top: $margin-base;
  & .HelpTooltip {
    cursor: default;
  }
}

.FieldSuggestionValidationModal__failureTitle {
  text-align: center;
  font-weight: 500;
}

.FieldSuggestionValidationModal__failuresHeader {
  font-style: italic;
  border-bottom: 1px solid $gray-dark;
  margin: $half-margin-base 0;
}

.PayloadModal__pagination {
  margin-bottom: 1em;
}

.PayloadModal__table {
  thead {
    border: 1px solid;
  }
  th,
  td {
    padding: 1em 1em;
    border: 1px solid;
  }
}

@import '@alkem/react-styles/src/vars';

$row-height-small: 50px;
$row-height: 74px;

.FieldSuggestionRow {
  cursor: auto;

  background-color: white;
  border: 1px solid $gray-border;
  height: $row-height;
  margin: $padding-small-vertical 0;
  align-items: center;
  & .row {
    margin-left: 0;
  }

  &:hover {
    background-color: $alk-background-hover;
  }

  &.active {
    background-color: $brand-primary-active;
    color: white;

    .FieldSuggestionRow__brand,
    .FieldSuggestionRow__classification {
      border: 1px solid white;
      background: none;
      color: white;
      border-color: white;
    }

    .checkbox + label:before,
    .checkbox:checked + label:after {
      border-color: white;
    }
  }

  &--first {
    margin-top: 3.5rem;
  }
}

.FieldSuggestionRow__picture {
  img {
    height: 68px;
  }
}

.FieldSuggestionRow__suggestedValue {
  padding: $padding-xs-vertical $padding-xs-horizontal;
  margin: 1px 0;
  font-weight: 500;
  &.FieldSuggestionRow__suggestedValue--warning {
    padding: $padding-xs-vertical $padding-xs-horizontal;
    margin: 1px 0;
    font-weight: 500;
    color: #ffae42;
  }
}
.FieldSuggestionRow__suggestedValue .Ellitips__2 {
  /* autoprefixer: off */
  -webkit-box-pack: inherit;
  -webkit-box-orient: vertical;
  height: auto;
}
/* autoprefixer: on */

.FieldSuggestionRow__fmdName {
  width: 100%;
  font-size: $font-size-small;
  color: $brand-secondary;
  border-radius: $border-radius-very-large;
  text-align: center;
  padding: $padding-xs-vertical $padding-xs-horizontal;
  border: 1px solid $brand-secondary;
  margin: 1px 0;
}

.FieldSuggestionRow__score {
  width: 100%;
  margin-left: -10px;
  padding-top: 6px;
}

.FieldSuggestionRow__checkbox {
  display: flex;
  height: 100%;
  cursor: default;
  align-items: center;
  margin-left: 10px;

  & > .Checkbox {
    display: block;
    width: 15px;
    height: 15px;
    padding: 0;

    & > .checkbox + label {
      display: block;
      width: 15px;
      height: 15px;
      padding: 0;

      &:before {
        top: 0;
        margin-right: 0;
        cursor: pointer;
      }

      &:after {
        top: 1px;
        cursor: pointer;
      }
    }
  }
}

.FieldSuggestionRow__col {
  position: relative;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  &--first {
    margin-left: -5px;
  }
}

.FieldSuggestionRow__header {
  position: absolute;
  width: 100%;
  bottom: 120%;
  color: $gray-medium;
  text-overflow: ellipsis;
  cursor: auto;
  pointer-events: none;
  display: flex;
  flex-direction: column;
  padding-right: 10px;
}

.FieldSuggestionRow__link {
  color: inherit;
  &:hover {
    color: inherit;
    text-decoration: none;
  }
}

.FieldSuggestionRow__action_validate {
  font-size: xx-large;
  cursor: pointer;
  color: $brand-success;
}

.FieldSuggestionRow__action_validate--warning {
  font-size: xx-large;
  cursor: pointer;
  color: #d3d3d3;
}

.FieldSuggestionRow__action_refuse {
  font-size: xx-large;
  cursor: not-allowed;
  color: $brand-danger;
}

.FieldSuggestionRow__action_disabled {
  font-size: xx-large;
  opacity: 0.3;
}

.FieldSuggestionRow__action_showmore {
  font-size: xx-large;
  transform: scaleX(-1);
  cursor: pointer;
}

.FieldSuggestionRow__SubRow {
  height: 100%;
  & .ReactUiCheckbox {
    margin-bottom: 0;
  }
}

.FieldSuggestionRow__helptooltip {
  cursor: pointer;
  color: $gray-medium;
  margin-left: 0.4em;
}

.buttonsContainer {
  display: flex;
  justify-content: flex-end;
  margin-top: 15px;
}

.container {
  :global(.InputWithLabel) {
    margin-top: 0.25em;
  }
}

@import 'dashboards/shared/styles/variables';

.UITextInput {
  width: 100%;

  &__Input {
    width: 100%;
    height: 48px;
    padding: 0 30px 0 20px;
    border: 1px solid $gray-light;
    border-radius: 10px;
    font-size: $font-medium;

    &:disabled {
      background: $gray-lighter;
    }
  }
}

@import 'dashboards/shared/styles/variables';

.UISingleSelect {
  width: 100%;
  position: relative;

  &__Input {
    width: 100%;
    height: 48px;
    display: flex;
    align-items: center;
    padding: 0 30px 0 20px;
    border: 1px solid $gray-light;
    border-radius: 10px;
    font-size: $font-medium;

    &:disabled {
      background: $gray-lighter;
    }
  }

  &__Clear {
    position: absolute;
    right: 15px;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
    font-size: $font-large;
    color: $gray;
  }

  &__Dropdown {
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    max-height: calc(4 * 48px);
    overflow-y: auto;
    background: $white;
    border: 1px solid $gray-light;
    border-radius: 10px;
    list-style: none;
    margin: 0;
    padding: 0;
    z-index: 1;

    &__Container {
      position: relative;
    }

    &--Empty {
      color: $gray;
    }

    li {
      height: 48px;
      display: flex;
      align-items: center;
      padding: 0 20px;
      cursor: pointer;
      font-size: $font-medium;

      &:hover {
        background: $gray-lighter;
      }
    }
  }
}

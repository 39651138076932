@import '@alkem/react-styles/src/vars';

.ProductList__row {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.ProductList__col {
  display: flex;
  align-items: center;
}

.ProductList__pagination {
  display: inline-flex;
}

.ProductList__paginationInformation {
  color: $brand-primary;
  padding: 0 10px;
  text-align: right;
  flex-grow: 1;
}

.ProductList__noResults {
  text-align: center;
  color: $gray;
}
